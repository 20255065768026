import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BuildIcon from "@material-ui/icons/Build";
import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import Toolbar from "@material-ui/core/Toolbar";
import DatosUsuario from "./DatosUsuario";
import Herramientas from "./Herramientas";
import { isValidTrabajador } from "../../functions/AuthFunctions";

function TabPanel({ value, localData }) {
    return (
        <div>
            {value === 0 && isValidTrabajador() && <Herramientas localData={localData} />}
            {value === 1 && <DatosUsuario localData={localData} />}
        </div>
    );
}
function UsuarioPageMobile({ localData }) {
    const [selectedTab, setSelectedTab] = React.useState(0);

    const handleChange = (event, newValue) => {
        setSelectedTab(newValue);
    };
    return (
        <div>
            <Toolbar />
            <Tabs
                className={`${localData.modo === "oscuro" ? "bg-dark" : "bg-light"}`}
                variant='fullWidth'
                value={selectedTab}
                indicatorColor='primary'
                textColor='primary'
                onChange={handleChange}
                aria-label='icon label tabs example'
                disabled
            >
                <Tab
                    icon={isValidTrabajador() ? <BuildIcon /> : <BusinessIcon />}
                    label={isValidTrabajador() ? "Herramientas" : "Proyectos"}
                />
                <Tab icon={<PersonIcon />} label='Usuario' />
            </Tabs>
            <TabPanel value={selectedTab} localData={localData}></TabPanel>
        </div>
    );
}

export default UsuarioPageMobile;
