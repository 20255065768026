export function getDatosEstructurales(tipo, campo) {
    return {
        tipo,
        campo,
        datos: [],
        filtrados: [],
    };
}

export function getDatosFiltrado(camposFiltrado) {
    const valores = {};

    camposFiltrado.forEach((campo) => {
        valores[campo] = "";
    });

    return {
        filtros: {
            opciones: {
                agrupacionid: [],
                subagrupacionid: [],
                contenidoid: [],
                relacionados: [],
            },
            valores,
        },
        texto: "",
    };
}

export function getDatosOpcionesTabla() {
    return {
        posicionMover: -1,
        pagina: 0,
        filasPagina: 5,
        datosModal: { entrada: null, campo: null },
    };
}
