import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {  useLocation,useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
    rootList: {
        width: "100%",
        backgroundColor: "none",
        position: "sticky",
        height: "calc(100vh - 70px)",
        top: "70px",
    },
}));

function DefaultSideMenu({ claves, selectedIndex, setSelectedIndex, localData }) {

  

    const handleListItemClick = (index) => {
        setSelectedIndex(index);
        const key= claves[index];
        const contactElement = document.getElementById(key);
        if (contactElement) {
          contactElement.scrollIntoView({ behavior: 'smooth' });
        }
    };
    
    const capatilizeString = (string) => {
        return string.trim().replace(/^\w/, (c) => c.toUpperCase());
    };
    const classes = useStyles();
    return (
        <>
            <Toolbar />

            <List component='nav' className={clsx("nav-submenu", classes.rootList)}>
                {claves.map((clave, index) => (
                    <ListItem
                        key={clave}
                        className={`pt-0 pb-0`}
                        button
                        onClick={() => handleListItemClick(index)}
                        selected={selectedIndex === index}
                    >
                        <ListItemText
                            primaryTypographyProps={{ noWrap: true }}
                            primary={capatilizeString(clave)}
                            className={`${localData.modo === "oscuro" ? "color-letras" : "color-letras-dark"} `}
                        />
                    </ListItem>
                ))}
            </List>
        </>
    );
}

export default DefaultSideMenu;
