import React from "react";
import ForwardIcon from "@material-ui/icons/Forward";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import IconButton from "@material-ui/core/IconButton";
import TableCell from "@material-ui/core/TableCell";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { fetchModificar, fetchdeleteRow, fetchContent } from "../../funciones/PeticionesServidor";
import { getIndexEntrada, filtrarDatos } from "../../funciones/FilasFunciones";

function OpcionesFilas({ info, setInfo, entrada, opciones, setOpciones, filtrado }) {
    const [mensajeSnack, setMensajeSnack] = React.useState("");
    const [severitySnack, setSeveritySnack] = React.useState("success");

    const deleteRow = () => {
        const indice = getIndexEntrada(entrada, info.filtrados);

        const idEliminado = info.filtrados[indice].id;

        const cambiados = [];
        info.datos.forEach((entradaDatos) => {
            const ordenEntrada = parseInt(entradaDatos["orden"]);

            if (ordenEntrada > indice) {
                if (info.campo === "" || entradaDatos[info.campo] === entrada[info.campo]) {
                    entradaDatos["orden"] = (ordenEntrada - 1).toString();
                    cambiados.push(entradaDatos);
                }
            }
        });

        const respuestaSnack = window.confirm("¿Eliminar la entrada?");
        if (respuestaSnack) {
            if (cambiados.length > 0) {
                fetchModificar(info.tipo, JSON.stringify(cambiados));
            }
            fetchdeleteRow(info.tipo, idEliminado).then(() => {
                const infoCopia = { ...info };
                fetchContent(info.tipo).then((response) => {
                    infoCopia.datos = JSON.parse(response.message);
                    infoCopia.filtrados = JSON.parse(response.message);
                    setInfo(infoCopia);
                    const opcionesCopia = { ...opciones };
                    filtrarDatos(infoCopia, setInfo, opciones, setOpciones, filtrado);
                    setOpciones(opcionesCopia);
                    setSeveritySnack("success");
                    setMensajeSnack("Entrada eliminada.");
                });
            });
        } else {
            setSeveritySnack("error");
            setMensajeSnack("Entrada no eliminada.");
        }
    };

    const modificar = async () => {
        const indiceFiltrados = getIndexEntrada(entrada, info.filtrados);

        const respuestaSnack = window.confirm("¿Modificar la entrada?");
        if (respuestaSnack) {
            await fetchModificar(info.tipo, JSON.stringify([info.filtrados[indiceFiltrados]]));
            setSeveritySnack("success");
            setMensajeSnack("Entrada guardada.");
        } else {
            setSeveritySnack("error");
            setMensajeSnack("Entrada no guardada.");
        }
    };

    const swapRows = async () => {
        if (opciones.posicionMover === -1) {
            const indiceFiltrados = getIndexEntrada(entrada, info.filtrados);
            const opcionesCopia = { ...opciones };
            opcionesCopia.posicionMover = indiceFiltrados;
            setOpciones(opcionesCopia);
        } else {
            const opcionesCopia = { ...opciones };
            opcionesCopia.posicionMover = -1;
            const entradaAnterior = info.filtrados[opciones.posicionMover];

            setOpciones(opcionesCopia);
            if (entradaAnterior !== entrada) {
                if (info.campo === "" || entradaAnterior[info.campo] === entrada[info.campo]) {
                    /** Cambiar ordenes */
                    const entradaOrden = entrada["orden"];
                    const entradaAnteriorOrden = entradaAnterior["orden"];

                    entrada["orden"] = entradaAnteriorOrden;
                    entradaAnterior["orden"] = entradaOrden;

                    fetchModificar(info.tipo, JSON.stringify([entrada, entradaAnterior])).then(() => {
                        const infoCopia = { ...info };
                        fetchContent(info.tipo).then((response) => {
                            infoCopia.datos = JSON.parse(response.message);
                            infoCopia.filtrados = JSON.parse(response.message);
                            setInfo(infoCopia);
                            filtrarDatos(infoCopia, setInfo, opciones, setOpciones, filtrado);
                            setOpciones(opcionesCopia);
                        });
                    });
                }
            }
        }
    };

    return (
        <>
            {info.tipo !== "contenidos" && (
                <TableCell onClick={swapRows}>
                    <IconButton
                        className={opciones.posicionMover === info.filtrados.indexOf(entrada) ? "btn-success" : ""}
                        variant='outlined'
                        color='primary'
                        size='small'
                    >
                        <ForwardIcon />
                    </IconButton>
                </TableCell>
            )}
            <TableCell onClick={modificar}>
                <IconButton variant='outlined' color='primary' size='small'>
                    <SaveIcon />
                </IconButton>
            </TableCell>
            <TableCell onClick={deleteRow}>
                <IconButton variant='outlined' color='secondary' size='small'>
                    <DeleteIcon />
                </IconButton>
            </TableCell>
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={mensajeSnack !== ""}
                onClose={() => setMensajeSnack("")}
                autoHideDuration={5000}
            >
                <MuiAlert elevation={6} variant='filled' onClose={() => setMensajeSnack("")} severity={severitySnack}>
                    {mensajeSnack}
                </MuiAlert>
            </Snackbar>
        </>
    );
}

export default OpcionesFilas;
