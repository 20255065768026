/** Llamadas API genericas */

export const fetchPost = async (url, dataPOST, opcion) => {
    dataPOST.append("opcion", opcion);
    const res = await fetch(url, {
        method: "POST",
        body: dataPOST,
    });
    const data = await res.json();
    return data;
};

export const fetchContent = async (opcion) => {
    const url = `${process.env.REACT_APP_API_URL}/ahAdminFiles/baseDatos/datos_entradas.php`;

    return fetchPost(url, new FormData(), opcion);
};

export const fetchModificar = async (opcion, objetosJSON) => {
    const dataPOST = new FormData();
    dataPOST.append("objetos", objetosJSON);

    const url = `${process.env.REACT_APP_API_URL}/ahAdminFiles/baseDatos/modificar_entrada.php`;

    return fetchPost(url, dataPOST, opcion);
};

export const fetchdeleteRow = async (opcion, id) => {
    const dataPOST = new FormData();
    dataPOST.append("id", id);

    const url = `${process.env.REACT_APP_API_URL}/ahAdminFiles/baseDatos/eliminar_entrada.php`;

    return fetchPost(url, dataPOST, opcion);
};

export const fetchCrearNuevaEntrada = async (opcion, orden, valorIdExterno, campoId) => {
    const dataPOST = new FormData();
    dataPOST.append("orden", orden);
    dataPOST.append("valorIdExterno", valorIdExterno);
    dataPOST.append("campoId", campoId);

    const url = `${process.env.REACT_APP_API_URL}/ahAdminFiles/baseDatos/crear_entrada.php`;

    return fetchPost(url, dataPOST, opcion);
};

export const fetchOpcionesFormularios = async () => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/ahAdminFiles/baseDatos/opciones_formularios.php`);
    const data = await res.json();
    return data;
};
export const cambiarFila = (event, index, campo, datosTabla, setDatosTabla) => {
    const datosTablaNuevos = [...datosTabla];
    const entrada = datosTabla[index];
    entrada[campo] = event.target.value;

    datosTablaNuevos[index] = entrada;

    setDatosTabla(datosTablaNuevos);
};
