import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Form from "react-bootstrap/Form";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import PaginacionTabla from "../comunes/componentes/opcionesTablas/PaginacionTabla";

import { getDatosEstructurales, getDatosOpcionesTabla } from "../comunes/funciones/DatosPaneles";
import { fetchDocumentos, uploadFile, deleteFichero, filtrar } from "./DocumentosFunciones";
import { getIndexEntrada } from "../comunes/funciones/FilasFunciones";

function TextInputComponent({ valor, abrirModal }) {
    return (
        <TextField
            variant='filled'
            style={{ minWidth: 150 }}
            disabled
            fullWidth
            value={valor}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end' onClick={() => abrirModal(valor)}>
                        <Tooltip title='Ampliar texto'>
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                ),
            }}
        />
    );
}

const capatilizeString = (string) => {
    return string.trim().replace(/^\w/, (c) => c.toUpperCase());
};

function DocumentosPage() {
    const [info, setInfo] = useState(getDatosEstructurales("documentos", ""));
    const [opciones, setOpciones] = useState(getDatosOpcionesTabla());
    const [valorModal, setValorModal] = useState("");
    const [filtrado, setFiltrado] = useState({ texto: "", tipo: "" });
    const [directorios, setDirectorios] = useState([]);

    useEffect(() => {
        fetchDocumentos().then((informacionServidor) => {
            const infoCopia = { ...info };
            infoCopia.datos = JSON.parse(informacionServidor.message)["documentos"];
            infoCopia.filtrados = JSON.parse(informacionServidor.message)["documentos"];

            setDirectorios(JSON.parse(informacionServidor.message)["directorios"]);
            setInfo(infoCopia);
        });
    }, []);

    const cambiarTipo = (event, entrada) => {
        const indiceFiltrado = getIndexEntrada(entrada, info.filtrados);
        const indiceDatos = getIndexEntrada(entrada, info.datos);

        entrada.tipo = event.target.value;

        const infoCopia = { ...info };
        infoCopia.datos[indiceDatos] = entrada;
        infoCopia.filtrados[indiceFiltrado] = entrada;

        setInfo(infoCopia);
    };

    const nuevaEntrada = () => {
        const infoCopia = { ...info };
        infoCopia.datos.push({ tipo: "publicos", nombre: "", direccion: "" });
        infoCopia.filtrados = [...infoCopia.datos];
        const opcionesCopia = { ...opciones };

        opcionesCopia.pagina = Math.floor(info.filtrados.length / opcionesCopia.filasPagina);

        setOpciones(opcionesCopia);

        setInfo(infoCopia);
    };

    // Filtros

    const cambiarFiltroTexto = (event) => {
        const filtradoCopia = { ...filtrado };
        filtradoCopia.texto = event.target.value;

        setFiltrado(filtradoCopia);
    };

    const buscarTipo = (valor) => {
        const filtradoCopia = { ...filtrado };
        filtradoCopia.tipo = valor;

        setFiltrado(filtradoCopia);
    };

    const cambiarFiltroTipo = (event) => {
        buscarTipo(event.target.value);
    };

    // Modal campo
    const abrirModal = (valor) => {
        setValorModal(valor);
    };

    const cerrarModal = () => {
        setValorModal("");
    };

    return (
        <div>
            <div className='row mt-3 mb-3 '>
                <div className='col-sm-5 '>
                    <TextField
                        variant='filled'
                        color='secondary'
                        fullWidth
                        value={filtrado.texto}
                        label='Clave para filtrado'
                        onChange={cambiarFiltroTexto}
                    />
                </div>
                <div className='col-sm-3'>
                    <FormControl fullWidth color='secondary'>
                        <InputLabel>Directorio</InputLabel>
                        <Select
                            variant='filled'
                            color='secondary'
                            fullWidth
                            value={filtrado.tipo}
                            onChange={(event) => cambiarFiltroTipo(event)}
                        >
                            <MenuItem value={""}>Ninguno</MenuItem>;
                            {directorios.map((directorio) => {
                                return <MenuItem value={directorio}>{capatilizeString(directorio)}</MenuItem>;
                            })}
                        </Select>
                    </FormControl>
                </div>
                <div className='col-sm-2'>
                    <Button
                        variant='secondary'
                        className='w-100'
                        size='lg'
                        onClick={() => filtrar(filtrado, info, setInfo, opciones, setOpciones)}
                    >
                        Filtrar
                    </Button>
                </div>
                <div className='col-sm-2'>
                    <Button variant='primary' className='w-100' size='lg' onClick={nuevaEntrada}>
                        Nueva entrada
                    </Button>
                </div>
            </div>
            <Paper>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Carpeta contenedora</TableCell>
                                <TableCell>Nombre Fichero</TableCell>
                                <TableCell>Fichero</TableCell>
                                <TableCell>Eliminar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {info.filtrados
                                .slice(
                                    opciones.pagina * opciones.filasPagina,
                                    opciones.pagina * opciones.filasPagina + opciones.filasPagina
                                )
                                .map((entrada, index) => {
                                    return (
                                        <TableRow
                                            key={index}
                                            /** Meter colorines */
                                        >
                                            <TableCell>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <Select
                                                            fullWidth
                                                            value={entrada.tipo}
                                                            onChange={(event) => cambiarTipo(event, entrada)}
                                                        >
                                                            {directorios.map((directorio) => {
                                                                return (
                                                                    <MenuItem value={directorio}>
                                                                        {capatilizeString(directorio)}
                                                                    </MenuItem>
                                                                );
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <div className='col-2'>
                                                        <Tooltip
                                                            title='Poner clave en buscador'
                                                            onClick={() => buscarTipo(entrada.tipo)}
                                                        >
                                                            <IconButton>
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <TextInputComponent valor={entrada.nombre} abrirModal={abrirModal} />
                                            </TableCell>
                                            <TableCell>
                                                <div class='row' style={{ width: 120 }}>
                                                    <div class='col-sm-6'>
                                                        <Tooltip title='Subir fichero nuevo' arrow>
                                                            <Form>
                                                                <Form.File
                                                                    onChange={(event) =>
                                                                        uploadFile(event, entrada, info, setInfo)
                                                                    }
                                                                    custom
                                                                    as='div'
                                                                    className='input-upload'
                                                                />
                                                            </Form>
                                                        </Tooltip>
                                                    </div>
                                                    <div class='col-sm-6'>
                                                        <Tooltip title='Descargar fichero actual' arrow>
                                                            <IconButton
                                                                variant='outlined'
                                                                color='primary'
                                                                size='small'
                                                                target='_blank'
                                                                href={process.env.REACT_APP_SRC_URL + entrada.direccion}
                                                            >
                                                                <CloudDownloadOutlinedIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <TableCell onClick={() => deleteFichero(entrada, info, setInfo)}>
                                                    <IconButton color='secondary' size='small'>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <PaginacionTabla info={info} opciones={opciones} setOpciones={setOpciones} />
            </Paper>
            <Dialog open={valorModal !== ""} maxWidth='sm' fullWidth={true}>
                <DialogTitle>Valor del campo</DialogTitle>
                <DialogContent className='pb-3'>
                    <TextField
                        variant='filled'
                        style={{ minWidth: 150 }}
                        value={valorModal}
                        disabled
                        fullWidth
                        className=' mb-3 w-100'
                    />

                    <Button variant='primary' block onClick={cerrarModal}>
                        Cerrar
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default DocumentosPage;
