import { useEffect, useState, useRef } from "react";

const isLandscape = () => window.matchMedia("(orientation:landscape)").matches;

function Portada() {
    const [datosPortada, setDatosPortada] = useState({ imagenes: [] });

    const [indexImagen, setIndexImagen] = useState(0);
    const [orientation, setOrientation] = useState(isLandscape() ? "landscape" : "portrait");

    const indexRef = useRef(indexImagen);
    indexRef.current = indexImagen;

    const datosPortadaRef = useRef(datosPortada);
    datosPortadaRef.current = datosPortada;

    const fetchDatosPortada = async () => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/informacionWeb/get_datos_portada.php`);
        const data = await res.json();
        return JSON.parse(data.message);
    };

    const cambiarImagen = () => {
        let incremento = indexRef.current + 1;
        if (incremento >= datosPortadaRef.current.imagenes.length) {
            incremento = 0;
        }
        if (orientation === "portrait") {
            if (incremento >= datosPortadaRef.current.imagenes_movil.length) {
                incremento = 0;
            }
        }

        setIndexImagen(incremento);
    };

    const onWindowResize = () => {
        clearTimeout(window.resizeLag);
        window.resizeLag = setTimeout(() => {
            delete window.resizeLag;
            cambiarImagen();
            setOrientation(isLandscape() ? "landscape" : "portrait");
        }, 200);
    };

    useEffect(() => {
        fetchDatosPortada().then((informacionServidor) => {
            setDatosPortada(informacionServidor);
        });

        window.addEventListener("resize", onWindowResize);

        const timer = setInterval(() => {
            cambiarImagen();
        }, 20 * 1000); // clearing interval
        return () => clearInterval(timer);
    }, []);

    const getDatosPortadaMovil = (index) => {
        if (index > datosPortada.imagenes_movil.length - 1) {
            index = Math.random() * datosPortada.imagenes_movil.length - 1;
        }

        return datosPortada.imagenes_movil[index];
    };

    return (
        <div style={{ overflow: "hidden" }}>
            {/* <button style={{ position: "fixed", zIndex: 400, top: "200px" }} onClick={cambiarImagen}>
                Next
            </button> */}
            {datosPortada.imagenes.map((src, index) => {
                return (
                    <div
                        key={index}
                        style={{
                            backgroundImage: `url(${process.env.REACT_APP_SRC_URL}${
                                orientation === "portrait" ? getDatosPortadaMovil(index) : src
                            })`,
                        }}
                        className={`container-fluid imagen-portada ${
                            index !== indexImagen ? "imagen-portada-fade" : "active"
                        } ${orientation === "portrait" ? "imagen-portada-vert" : ""} `}
                    ></div>
                );
            })}
        </div>
    );
}

export default Portada;
