import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Form from "react-bootstrap/Form";
import { getIndexEntrada } from "../../funciones/FilasFunciones";

function checkValidContenido(campo, datosFichero) {
    const isValidMarkdown = datosFichero.type.includes("markdown") && campo.includes("markdown");
    const isValidImagen = datosFichero.type.includes("image") && !campo.includes("markdown");

    return isValidMarkdown || isValidImagen;
}

function checkValidDetalles(entrada, datosFichero) {
    const isValidMarkdown = entrada.tipo === "texto" && datosFichero.type.includes("markdown");
    const isValidImagen = entrada.tipo === "imagen" && datosFichero.type.includes("image");

    return isValidMarkdown || isValidImagen;
}

function ControlesFicheros({ entrada, campo, info, setInfo }) {
    const fetchUploadFile = async (datosFichero, tipo) => {
        const dataPOST = new FormData();
        dataPOST.append("file", datosFichero);
        dataPOST.append("tipo", tipo);

        const opcion = campo === "src" ? "detalles" : "contenidos";
        dataPOST.append("opcion", opcion);
        dataPOST.append("datosEntrada", JSON.stringify(entrada));

        const res = await fetch(`${process.env.REACT_APP_API_URL}/ahAdminFiles/baseDatos/upload_file.php`, {
            method: "POST",
            body: dataPOST,
        });
        const data = await res.json();
        return data;
    };

    const uploadFile = (event) => {
        const datosFichero = event.target.files[0];
        

        let tipo = "";
        if (
            (campo !== "src" && checkValidContenido(campo, datosFichero)) ||
            (campo === "src" && checkValidDetalles(entrada, datosFichero))
        ) {
            tipo = campo.includes("markdown") ? "markdown" : "image";
            if (tipo === "markdown") {
                if (campo === "src") {
                    tipo = campo.includes("_es") ? tipo + "_es" : tipo + "_en";
                } else {
                    tipo = entrada.ingles === 0 ? tipo + "_es" : tipo + "_en";
                }
            }
        }

        if (tipo !== "") {
            fetchUploadFile(datosFichero, tipo).then((response) => {
                const indiceFiltrado = getIndexEntrada(entrada, info.filtrados);
                const indiceDatos = getIndexEntrada(entrada, info.datos);

                entrada[campo] = response.message;
                const infoCopia = { ...info };
                infoCopia.datos[indiceDatos] = entrada;
                infoCopia.filtrados[indiceFiltrado] = entrada;

                setInfo(infoCopia);
            });
        }
    };

    return (
        <div class='row' style={{ width: 120 }}>
            <div class='col-sm-6'>
                <Tooltip title='Subir fichero nuevo' arrow>
                    <Form>
                        <Form.File onChange={uploadFile} custom as='div' className='input-upload' />
                    </Form>
                </Tooltip>
            </div>
            <div class='col-sm-6'>
                <Tooltip title='Descargar fichero actual' arrow>
                    <IconButton variant='outlined' color='primary' size='small' target='_blank' href={entrada[campo]}>
                        <CloudDownloadOutlinedIcon />
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
}

export default ControlesFicheros;
