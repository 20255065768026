import React from "react";
import ReactMarkdown from "react-markdown";
import RenderLinks from "./RenderLinks";
import Toolbar from "@material-ui/core/Toolbar";
import MDEditor from "@uiw/react-md-editor";
import Form from "react-bootstrap/Form";
import RenderCode from "./RenderCode";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

function EditorMarkdown({ openMenu }) {
    const [value, setValue] = React.useState("**Hello world!!!**");
    const [mostrarEditor, setMostrarEditor] = React.useState(true);
    const [imagenesIntroducidas, setImagenesIntroducidas] = React.useState([]);

    const uploadImageRequest = async (file) => {
        const dataPOST = new FormData();
        dataPOST.append("file", file);
        const res = await fetch(`${process.env.REACT_APP_API_URL}/upload_file.php`, {
            method: "POST",
            body: dataPOST,
        });
        const data = await res.json();
        return data;
    };

    const downloadRequest = async () => {
        const dataPOST = new FormData();
        dataPOST.append("texto", value);
        dataPOST.append("imagenes", btoa(JSON.stringify(imagenesIntroducidas)));
        const res = await fetch(`${process.env.REACT_APP_API_URL}/download_markdown.php`, {
            method: "POST",
            body: dataPOST,
        });
        const data = await res.json();
        return data;
    };

    const introducirImagen = async () => {
        const elementoInput = document.getElementById("inputFile");
        const datosFichero = elementoInput.files[0];

        if (datosFichero.type.includes("image")) {
            const respuesta = await uploadImageRequest(datosFichero);

            if (respuesta.status === 200) {
                const datosImagen = {
                    nombre: `${datosFichero.name}_imagen`,
                    urlTmp: `${respuesta.message}`,
                };

                const imagenAdd = `[${datosImagen.nombre}](${process.env.REACT_APP_SRC_URL}${datosImagen.urlTmp})`;

                const valueInput = value + imagenAdd;

                setImagenesIntroducidas([...imagenesIntroducidas, datosImagen]);
                setValue(valueInput);
            }
        }
        document.getElementById("formInput").style.display = "none";
    };

    const descargarMarkdown = () => {
        downloadRequest().then((respuesta) => {
            if (respuesta.status === 200) {
                const enlaceDescarga = document.createElement("a");
                enlaceDescarga.href = respuesta.message;

                const valueInput = value.replaceAll("/tmp/", "/imagenes/markdowns/");
                setValue(valueInput);
                enlaceDescarga.click();
            }
        });
    };

    const showExaminar = () => {
        document.getElementById("formInput").style.display = "block";
    };

    const notShowExaminar = () => {
        document.getElementById("formInput").style.display = "none";
    };

    const cambiarEditor = () => {
        setMostrarEditor(!mostrarEditor);
    };

    return (
        <>
            <Toolbar />

            <div className={`container-fluid contendor-editar ${openMenu === true ? "limitar-ancho-editor" : ""}`}>
                <div className='row'>
                    <div className='col-sm'>
                        <button className='w-100 btn btn-primary mb-2' onClick={descargarMarkdown}>
                            Descargar
                        </button>
                    </div>
                </div>
                <div className='row h-100'>
                    {mostrarEditor && (
                        <div className='col-sm-6 h-100' onDragEnter={showExaminar} onDragExit={notShowExaminar}>
                            <Form id='formInput' className='inputFileMarkdown'>
                                <Form.File
                                    className='fileInputMarkdown'
                                    id='inputFile'
                                    label=''
                                    data-browse=''
                                    custom
                                    onChange={introducirImagen}
                                />
                            </Form>

                            <MDEditor
                                className='h-100'
                                value={value}
                                onChange={setValue}
                                height='100%'
                                preview='edit'
                            />
                        </div>
                    )}
                    <div className='col-sm bg-white color-letras-dark '>
                        <div className='text-right'>
                            {mostrarEditor && (
                                <Tooltip title='Ocultar editor' aria-label='ocultar'>
                                    <IconButton className='botonColor linksToolbar' onClick={cambiarEditor}>
                                        <VisibilityOffIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                            {!mostrarEditor && (
                                <Tooltip title='Mostrar editor' aria-label='mostrar'>
                                    <IconButton className='botonColor linksToolbar' onClick={cambiarEditor}>
                                        <VisibilityIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                        <div className='mostrar-editor'>
                            <ReactMarkdown
                                children={value}
                                renderers={{ link: RenderLinks, code: RenderCode }}
                            ></ReactMarkdown>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditorMarkdown;
