import React from "react";

import FormularioContacto from "../apoyo/Formulario/FormularioContacto";
import FormularioMovil from "../apoyo/Formulario/FormularioMovil";
import GridList from "@material-ui/core/GridList";
import Typography from "@material-ui/core/Typography";
import { MobileView, BrowserView } from "react-device-detect";

import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";

import MoreInformation from "../detalles/MoreInformation";
import TextoContenidos from "../apoyo/TextoContenidos";
function capitalizeWord(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
function DisplayContenidos({ tituloMasInfo, objetos, esteParrafo, gridLimit, localData, opcion }) {
    const [objetoInfo, setObjetoInfo] = React.useState({});
    const [masInfo, setMasInfo] = React.useState(false);

    const abrirMasInfo = (objeto) => {
        setObjetoInfo(objeto);

        setMasInfo(true);
    };
    const abrirDetalles = (objeto) => {
        window.open("/#/project/" + objeto.contenidoid, "_top");
    };

    return (
        <div>
            <div className='mt-2'></div>
            {opcion === "news" && (
                <TextoContenidos
                    localData={localData}
                    titulo={localData.idioma === "es" ? "Contacta con nosotros" : "Contact with us"}
                    markdown={
                        localData.idioma === "es"
                            ? "documentos/varios/contacto_es.md"
                            : "documentos/varios/contacto_en.md"
                    }
                />
            )}
            {opcion === "group-ah" && (
                <TextoContenidos
                    localData={localData}
                    titulo={localData.idioma === "es" ? "Visión" : "Vision"}
                    markdown={
                        localData.idioma === "es" ? "documentos/varios/vision_es.md" : "documentos/varios/vision_en.md"
                    }
                />
            )}
            {Object.keys(objetos).map((clave, index) => (
                // Lista para los objetos que tenemos de una clave

                <GridList
                    onMouseOver={() => esteParrafo(index)}
                    cols={objetos[clave].length > gridLimit ? gridLimit : objetos[clave].length}
                    key={index + "_" + clave}
                    spacing={0}
                    cellHeight={300}
                >
                    <div id={clave} className='div-relleno'></div>

                    <Typography
                        cols={objetos[clave].length > gridLimit ? gridLimit : objetos[clave].length}
                        variant='h4'
                        style={{
                            height: "auto",
                        }}
                        className={`${localData.modo === "oscuro" ? "color-letras" : "color-letras-dark"} mb-0`}
                        // Titulo de la lista
                    >
                        {capitalizeWord(clave)}
                    </Typography>
                    {objetos[clave].map((objeto, indexObjects) => (
                        // Objetos uno por uno
                        <GridListTile
                            cols={1}
                            key={objeto.contenidoid + "_" + indexObjects}
                            onClick={objeto.detalles === "1" ? () => abrirDetalles(objeto) : () => abrirMasInfo(objeto)}
                            style={{ cursor: "pointer" }}
                        >
                            <img src={process.env.REACT_APP_SRC_URL + objeto.foto} alt={objeto.contenidoid} />
                            <GridListTileBar
                                className={`bg-box-dark`}
                                // Cambio de idioma
                                title={objeto.titulo}
                            />
                        </GridListTile>
                    ))}
                </GridList>
            ))}

            {opcion === "contact" && (
                <div>
                    <div id={tituloMasInfo} className='div-relleno'></div>
                    <Typography
                        variant='h4'
                        style={{
                            height: "auto",
                        }}
                        className={`${localData.modo === "oscuro" ? "color-letras" : "color-letras-dark"} mb-3 `}
                        // Titulo de la lista
                    >
                        {tituloMasInfo}
                    </Typography>
                    <BrowserView>
                        <FormularioContacto localData={localData} />
                    </BrowserView>
                    <MobileView>
                        <FormularioMovil localData={localData} />
                    </MobileView>
                </div>
            )}
            <MoreInformation
                localData={localData}
                open={masInfo}
                onClose={() => setMasInfo(false)}
                objeto={objetoInfo}
            />
        </div>
    );
}

export default DisplayContenidos;
