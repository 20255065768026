export const fetchDocumentos = async () => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/ahAdminFiles/ficheros/listado_ficheros.php`);
    const data = await res.json();
    return data;
};

export const fetchUploadFile = async (datosFichero, entrada) => {
    const dataPOST = new FormData();
    dataPOST.append("file", datosFichero);

    dataPOST.append("datosEntrada", JSON.stringify(entrada));

    const res = await fetch(`${process.env.REACT_APP_API_URL}/ahAdminFiles/ficheros/upload_file.php`, {
        method: "POST",
        body: dataPOST,
    });
    const data = await res.json();
    return data;
};

export const uploadFile = (event, entrada, info, setInfo) => {
    const datosFichero = event.target.files[0];

    // Mirar este if
    if (datosFichero.type.includes("markdown") || datosFichero.type.includes("image")) {
        fetchUploadFile(datosFichero, entrada).then((response) => {
            if (response.status === 200) {
                fetchDocumentos().then((response) => {
                    const infoCopia = { ...info };
                    infoCopia.datos = JSON.parse(response.message)["documentos"];
                    infoCopia.filtrados = JSON.parse(response.message)["documentos"];

                    setInfo(infoCopia);
                });
            }
        });
    }
};

export const fetchDelete = async (entrada) => {
    const dataPOST = new FormData();

    dataPOST.append("direccion", entrada.direccion);

    const res = await fetch(`${process.env.REACT_APP_API_URL}/ahAdminFiles/ficheros/delete_file.php`, {
        method: "POST",
        body: dataPOST,
    });
    const data = await res.json();
    return data;
};
export const deleteFichero = (entrada, info, setInfo) => {
    const respuestaSnack = window.confirm("¿Eliminar el documento?");
    if (respuestaSnack) {
        fetchDelete(entrada).then((response) => {
            if (response.status === 200) {
                fetchDocumentos().then((response) => {
                    const infoCopia = { ...info };
                    infoCopia.datos = JSON.parse(response.message)["documentos"];
                    infoCopia.filtrados = JSON.parse(response.message)["documentos"];

                    setInfo(infoCopia);
                });
            }
        });
    }
};

export const filtrar = (filtrado, info, setInfo, opciones, setOpciones) => {
    const infoCopia = { ...info };

    const datosValidos = [];
    info.datos.forEach((entrada) => {
        let isValidEntrada = true;
        if (filtrado.texto !== "") {
            isValidEntrada = false;
            Object.keys(entrada).forEach((clave) => {
                if (typeof entrada[clave] === "string") {
                    isValidEntrada = isValidEntrada || entrada[clave].toLowerCase().includes(filtrado.texto);
                }
            });
        }
        if (filtrado.tipo !== "") {
            isValidEntrada = isValidEntrada && entrada["tipo"] === filtrado.tipo;
        }

        if (isValidEntrada === true) {
            datosValidos.push(entrada);
        }
    });
    infoCopia.filtrados = datosValidos;
    setInfo(infoCopia);

    const opcionesCopia = { ...opciones };
    opcionesCopia.pagina = 0;

    setOpciones(opcionesCopia);
};
