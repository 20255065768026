export function getIndexEntrada(entrada, datos) {
    let indexReturn = -1;

    datos.forEach((entradaDatos, index) => {
        if (entrada.id === entradaDatos.id) {
            indexReturn = index;
        }
    });
    return indexReturn;
}

export function getOpcionesFormDinamic(filtrado, campoPadre, campoHijo) {
    if (filtrado.filtros.valores[campoPadre] !== "") {
        const relacionados = filtrado.filtros.opciones.relacionados;
        const valorPadre = filtrado.filtros.valores[campoPadre];
        if (campoPadre === "subagrupacionid") {
            if (
                filtrado.filtros.valores["agrupacionid"] !== "" &&
                valorPadre in relacionados[filtrado.filtros.valores["agrupacionid"]]
            ) {
                return relacionados[filtrado.filtros.valores["agrupacionid"]][valorPadre];
            }
        } else if (!Array.isArray(relacionados[valorPadre])) {
            return Object.keys(relacionados[valorPadre]);
        }
    }
    return filtrado.filtros.opciones[campoHijo];
}

export function limpiarString(cadena) {
    const acentos = { á: "a", é: "e", í: "i", ó: "o", ú: "u", Á: "A", É: "E", Í: "I", Ó: "O", Ú: "U" };
    return cadena
        .toLowerCase()
        .split("")
        .map((letra) => acentos[letra] || letra)
        .join("")
        .toString()
        .replace(/[^a-z]/g, "-");
}

export const filtrarDatos = (info, setInfo, opciones, setOpciones, filtrado) => {
    const infoCopia = { ...info };

    const datosValidos = [];

    // Puede dar error
    if (filtrado.texto === "" && (info.campo === "" || filtrado.filtros.valores[info.campo] === "")) {
        infoCopia.filtrados = [...info.datos];
        setInfo(infoCopia);
    } else {
        info.datos.forEach((entrada) => {
            let isValidEntrada = true;
            if (filtrado.texto !== "") {
                isValidEntrada = false;
                Object.keys(entrada).forEach((clave) => {
                    if (typeof entrada[clave] === "string") {
                        isValidEntrada = isValidEntrada || entrada[clave].toLowerCase().includes(filtrado.texto);
                    }
                });
            }
            if (info.campo !== "" && filtrado.filtros.valores[info.campo] !== "") {
                isValidEntrada = isValidEntrada && filtrado.filtros.valores[info.campo] === entrada[info.campo];
            }

            if (isValidEntrada === true) {
                datosValidos.push(entrada);
            }
        });
        infoCopia.filtrados = datosValidos;
        setInfo(infoCopia);

        const opcionesCopia = { ...opciones };
        opcionesCopia.pagina = 0;

        setOpciones(opcionesCopia);
    }
};
