import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/principal/Header";
import { makeStyles } from "@material-ui/core/styles";
import { HashRouter as Router, Redirect, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import DetailsPage from "./components/detalles/DetailsPage";

import ContenidosPage from "./components/contenidos/ContenidosPage";

import Login from "./components/login/Login";
import EditorMarkdown from "./components/markdown/EditorMarkdown";
import AdminPanel from "./components/admin/AdminPage.js";
import clsx from "clsx";
import Portada from "./components/principal/Portada";
import DialogoConfirmar from "./components/apoyo/DialogoConfirmar";

import DisplayMarkdown from "./components/markdown/DisplayMarkdown";
import { isValidTrabajador, isValidUser } from "./functions/AuthFunctions";
import UsuarioPage from "./components/usuario/UsuarioPage";
import UsuarioPageMobile from "./components/usuario/UsuarioPageMobile";
import {
  getDefaultLocalData,
  changeLocalData,
  SI_POLI,
  NO_POLI,
  fetchCambiarIdioma,
  fetchCambiarPais,
} from "./functions/AppFunctions";
import { isMobileOnly } from "react-device-detect";
import Footer from "./components/principal/Footer";
import { getDefaultAuthData } from "./functions/AuthFunctions";

const useStyles = makeStyles((theme) => ({
  rootDark: {
    display: "flex",
    backgroundColor: "rgba(0,0,0,1)",
    fontFamily: "Century Gothic Regular",
  },
  rootLight: {
    display: "flex",
    backgroundColor: "rgba(255,255,255,1)",
    fontFamily: "Century Gothic Regular",
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    maxWidth: "100%",
    minHeight: "100vh",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  contenido: {
    // padding: theme.spacing(3),
  },
}));

function UserRoute({ children, path }) {
  return (
    <Route
      path={path}
      render={() => {
        return isValidUser() ? children : <Redirect to="/login" />;
      }}
    ></Route>
  );
}

function PrivateRoute({ children, path }) {
  return (
    <Route
      path={path}
      render={() => {
        return isValidTrabajador() ? children : <Redirect to="/login" />;
      }}
    ></Route>
  );
}

function App() {
  const classes = useStyles();

  const [openMenu, setOpenMenu] = useState(false);
  const [localData, setLocalData] = useState(getDefaultLocalData());
  const [dialogPolitica, setDialogPolitica] = useState(false);
  const [dataUserLogged, setDataUserLogged] = useState(getDefaultAuthData());

  useEffect(() => {
    // document.getElementById("logobtn").click();

    checkPolitica();
    const idioma = localData.idioma;
    fetchCambiarIdioma(idioma).then((response) => {
      if (response.status === 200) {
        changeLocalData(localData, setLocalData, "idioma", idioma);
        window.location.reload();
      } else if (response.status === 202) {
        changeLocalData(localData, setLocalData, "idioma", idioma);
      }
    });
    const pais = localData.pais;
    fetchCambiarPais(pais).then((response) => {
      if (response.status === 200) {
        changeLocalData(localData, setLocalData, "pais", pais);
      } else if (response.status === 202) {
        changeLocalData(localData, setLocalData, "pais", pais);
      }
    });
  }, []);

  // Funciones para la politica de cookies
  const changePolitica = (opcion) => {
    if (opcion === true) {
      changeLocalData(localData, setLocalData, "politica", SI_POLI);
    }
  };

  const checkPolitica = () => {
    setLocalData(getDefaultLocalData());
    if (localData.politica === NO_POLI) {
      setDialogPolitica(true);
    }
  };

  const onlyAuth = false;
  // Cambiar menu

  return (
    <Router>
      <div
        className={
          localData.modo === "oscuro" ? classes.rootDark : classes.rootLight
        }
      >
        <Header
          setOpenMenu={setOpenMenu}
          openMenu={openMenu}
          localData={localData}
          setLocalData={setLocalData}
          checkPolitica={checkPolitica}
          dataUserLogged={dataUserLogged}
          setDataUserLogged={setDataUserLogged}
        />
        {localData.politica === NO_POLI && (
          <main>
            <Route path="/">
              <div className="h-100 w-100" onLoad={checkPolitica}></div>
            </Route>
          </main>
        )}


        {localData.politica === SI_POLI && (
          <main
            className={clsx(classes.content, {
              [classes.contentShift]: openMenu,
            })}
          >
            {/** Rutas publicas */}

            <Route path="/login">
              <div className={classes.contenido}>
                <Login localData={localData} />
              </div>
            </Route>

            
              <div>
                <Route exact path="/">
                  <Portada />
                </Route>
                <Route
                  path={"/trainn/:opcion"}
                  render={({ match }) => (
                    <div className={classes.contenido}>
                      <ContenidosPage
                        opcion={match.params.opcion}
                        localData={localData}
                      />
                    </div>
                  )}
                />
                <Route
                  path="/project/:id"
                  render={({ match }) => (
                    <div className={classes.contenido}>
                      <DetailsPage id={match.params.id} localData={localData} />
                    </div>
                  )}
                />

                <PrivateRoute path="/admin">
                  <div className={classes.contenido}>
                    <AdminPanel localData={localData} />
                  </div>
                </PrivateRoute>

                <PrivateRoute path="/editor">
                  <EditorMarkdown openMenu={openMenu} />
                </PrivateRoute>

                <UserRoute path="/user">
                  <div className={classes.contenido}>
                    {isMobileOnly && (
                      <UsuarioPageMobile localData={localData} />
                    )}
                    {!isMobileOnly && <UsuarioPage localData={localData} />}
                  </div>
                </UserRoute>

                <div className={`${openMenu ? "d-none" : ""}`}>
                  <Footer localData={localData} />
                </div>
              </div>

          </main>
        )}

        <DialogoConfirmar
          openModal={dialogPolitica}
          onClose={() => setDialogPolitica(false)}
          confirmacion={changePolitica}
          titulo={localData.idioma === "es" ? "Tú privacidad" : "Your privacy"}
          texto={
            <div className="markdown-cookies">
              <DisplayMarkdown
                markdownName={
                  localData.idioma === "es"
                    ? "documentos/legales/cookies.md"
                    : "documentos/legales/cookies.md"
                }
              />
            </div>
          }
          localData={localData}
        />
      </div>
    </Router>
  );
}

export default App;
