import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Switch from "@material-ui/core/Switch";
import Tooltip from "@material-ui/core/Tooltip";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "react-bootstrap/Button";
import { useEffect } from "react";

import DialogCampos from "../comunes/componentes/opcionesTablas/DialogoCampos";
import OpcionesFilas from "../comunes/componentes/opcionesTablas/OpcionesFilas";
import PaginacionTabla from "../comunes/componentes/opcionesTablas/PaginacionTabla";

import FiltroTexto from "../comunes/componentes/filtros/FiltroTexto";
import BotonNuevaEntrada from "../comunes/componentes/filtros/BotonNuevaEntrada";
import AutoCompleteFiltro from "../comunes/componentes/filtros/AutoCompleteFiltro";

import ReadOnlyTextInput from "../comunes/componentes/inputs/ReadOnlyTextInput";
import TextInput from "../comunes/componentes/inputs/TextInput";

import ControlesFicheros from "../comunes/componentes/inputs/ControlesFicheros";

import { fetchContent, fetchOpcionesFormularios } from "../comunes/funciones/PeticionesServidor";
import { getDatosEstructurales, getDatosFiltrado, getDatosOpcionesTabla } from "../comunes/funciones/DatosPaneles";
import { filtrarDatos, getIndexEntrada } from "../comunes/funciones/FilasFunciones";

function ContenidosTab() {
    const [info, setInfo] = React.useState(getDatosEstructurales("contenidos", "contenidoid"));
    const [filtrado, setFiltrado] = React.useState(getDatosFiltrado(["contenidoid"]));
    const [opciones, setOpciones] = React.useState(getDatosOpcionesTabla());

    /**Fetch de datos */

    useEffect(() => {
        fetchContent(info.tipo).then((informacionServidor) => {
            const infoCopia = { ...info };
            infoCopia.datos = JSON.parse(informacionServidor.message);
            infoCopia.filtrados = JSON.parse(informacionServidor.message);
            console.log(infoCopia);
            setInfo(infoCopia);
        });
        fetchOpcionesFormularios().then((informacionServidor) => {
            const filtradoCopia = { ...filtrado };
            filtradoCopia.filtros.opciones = JSON.parse(informacionServidor.message);
            setFiltrado(filtradoCopia);
        });
    }, []);

    const changeDetalles = (entrada) => {
        const indiceFiltrado = getIndexEntrada(entrada, info.filtrados);
        const indiceDatos = getIndexEntrada(entrada, info.datos);
        const nuevoValorDetalles = entrada["detalles"] === "0" ? "1" : "0";

        entrada["detalles"] = nuevoValorDetalles;
        const infoCopia = { ...info };
        infoCopia.datos[indiceDatos] = entrada;
        infoCopia.filtrados[indiceFiltrado] = entrada;

        setInfo(infoCopia);
    };

    /** Cambiar Select */
    const changeCountry = (event, entrada) => {
        const indiceFiltrado = getIndexEntrada(entrada, info.filtrados);
        const indiceDatos = getIndexEntrada(entrada, info.datos);

        entrada["paises"] = event.target.value;

        const infoCopia = { ...info };
        infoCopia.datos[indiceDatos] = entrada;
        infoCopia.filtrados[indiceFiltrado] = entrada;

        setInfo(infoCopia);
    };

    /** Cambiar Select */
    const changeEstudio = (event, entrada) => {
        const indiceFiltrado = getIndexEntrada(entrada, info.filtrados);
        const indiceDatos = getIndexEntrada(entrada, info.datos);

        entrada["estudio"] = event.target.value;

        const infoCopia = { ...info };
        infoCopia.datos[indiceDatos] = entrada;
        infoCopia.filtrados[indiceFiltrado] = entrada;

        setInfo(infoCopia);
    };

    const isValidMover = (entrada) => {
        if (opciones.posicionMover !== -1) {
            const valorSubagrupacionId = info.filtrados[opciones.posicionMover].subagrupacionid;

            return valorSubagrupacionId === entrada.subagrupacionid;
        }
        return false;
    };

    return (
        <div>
            <div className='row mt-3 mb-3 '>
                <div className='col-sm-2 '>
                    <AutoCompleteFiltro
                        filtrado={filtrado}
                        setFiltrado={setFiltrado}
                        campo='contenidoid'
                        opciones={filtrado.filtros.opciones["contenidoid"]}
                    />
                </div>

                <div className='col-sm-6 '>
                    <FiltroTexto filtrado={filtrado} setFiltrado={setFiltrado} />
                </div>
                <div className='col-sm-2'>
                    <Button
                        variant='secondary'
                        onClick={() => filtrarDatos(info, setInfo, opciones, setOpciones, filtrado)}
                        className='w-100'
                        size='lg'
                    >
                        Filtrar
                    </Button>
                </div>
                <div className='col-sm-2'>
                    <BotonNuevaEntrada
                        info={info}
                        setInfo={setInfo}
                        opciones={opciones}
                        setOpciones={setOpciones}
                        filtrado={filtrado}
                    />
                </div>
            </div>

            <Paper>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Identificador</TableCell>
                                <TableCell>Titulo Español</TableCell>
                                <TableCell>Titulo Inglés</TableCell>
                                <TableCell>Foto</TableCell>
                                <TableCell>MD Español</TableCell>
                                <TableCell>MD Inglés</TableCell>
                                <TableCell>Proyecto</TableCell>
                                <TableCell>Paises</TableCell>
                                <TableCell>Estudio</TableCell>
                                <TableCell>Guardar</TableCell>
                                <TableCell>Eliminar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {info.filtrados
                                .slice(
                                    opciones.pagina * opciones.filasPagina,
                                    opciones.pagina * opciones.filasPagina + opciones.filasPagina
                                )
                                .map((entrada, index) => {
                                    return (
                                        <TableRow
                                            key={index}
                                            className={`${isValidMover(entrada) ? "bg-success" : ""}`}
                                            /** Meter colorines */
                                        >
                                            <TableCell>
                                                <ReadOnlyTextInput
                                                    info={info}
                                                    setInfo={setInfo}
                                                    entrada={entrada}
                                                    campo='contenidoid'
                                                    opciones={opciones}
                                                    setOpciones={setOpciones}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextInput
                                                    info={info}
                                                    setInfo={setInfo}
                                                    entrada={entrada}
                                                    campo='titulo_es'
                                                    opciones={opciones}
                                                    setOpciones={setOpciones}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextInput
                                                    info={info}
                                                    setInfo={setInfo}
                                                    entrada={entrada}
                                                    campo='titulo_en'
                                                    opciones={opciones}
                                                    setOpciones={setOpciones}
                                                />
                                            </TableCell>

                                            <TableCell>
                                                <ControlesFicheros
                                                    entrada={entrada}
                                                    campo='foto'
                                                    info={info}
                                                    setInfo={setInfo}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <ControlesFicheros
                                                    entrada={entrada}
                                                    campo='markdown_es'
                                                    info={info}
                                                    setInfo={setInfo}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <ControlesFicheros
                                                    entrada={entrada}
                                                    campo='markdown_en'
                                                    info={info}
                                                    setInfo={setInfo}
                                                />
                                            </TableCell>

                                            <TableCell>
                                                <Tooltip title='La entrada es un proyecto con más detalles' arrow>
                                                    <Switch
                                                        onChange={() => changeDetalles(entrada)}
                                                        checked={parseInt(entrada.detalles)}
                                                        color='primary'
                                                    />
                                                </Tooltip>
                                            </TableCell>

                                           
                                            <TableCell>
                                                <Select
                                                    value={entrada.paises}
                                                    onChange={(event) => changeCountry(event, entrada)}
                                                >
                                                    <MenuItem value={"ES"}>España</MenuItem>
                                                    <MenuItem value={"QA"}>Qatar</MenuItem>
                                                    <MenuItem value={"todos"}>Todos</MenuItem>
                                                </Select>
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    value={entrada.estudio}
                                                    onChange={(event) => changeEstudio(event, entrada)}
                                                >
                                                    <MenuItem value={"AH"}>Ah</MenuItem>
                                                    <MenuItem value={"RB3"}>RB3</MenuItem>
                                                    <MenuItem value={"ninguno"}>Ninguno</MenuItem>
                                                </Select>
                                            </TableCell>
                                            <OpcionesFilas
                                                info={info}
                                                setInfo={setInfo}
                                                opciones={opciones}
                                                setOpciones={setOpciones}
                                                entrada={entrada}
                                                filtrado={filtrado}
                                            />
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <PaginacionTabla info={info} opciones={opciones} setOpciones={setOpciones} />
            </Paper>
            <DialogCampos
                info={info}
                setInfo={setInfo}
                datosModal={opciones.datosModal}
                opciones={opciones}
                setOpciones={setOpciones}
            />
        </div>
    );
}

export default ContenidosTab;
