import React from "react";

import { useState, useEffect } from "react";

import DisplayMarkdown from "../markdown/DisplayMarkdown";

import DetailsSlider from "./DetailsSlider";
import FormularioContacto from "../apoyo/Formulario/FormularioContacto";
import FormularioMovil from "../apoyo/Formulario/FormularioMovil";

import Fab from "@material-ui/core/Fab";

import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {  useLocation } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import Typography from "@material-ui/core/Typography";

const etiquetasEn = ["About", "Images"];
const etiquetasEs = ["Información", "Imágenes"];

function DetailsPage({ id, localData }) {
    const tituloMasInfo = localData.idioma === "es" ? "Quiero saber más" : "I want to know more";
    const [textos, setTextos] = useState({});
    const [imagenes, setImagenes] = useState([]);
    const [infoGaleria, setInfoGaleria] = useState("info");
    const [estudio, setEstudio] = useState("Ninguno");
    const [titulo, setTitulo] = useState("Ninguno");
    const location = useLocation();
    

    // Get the overall information when the id parameter changes

    const fetchDetailsData = async () => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/informacionWeb/mas_informacion.php?elementId=${id}`);
        const data = await res.json();
        return data;
    };
    useEffect(() => {
        fetchDetailsData().then((informacionServidor) => {
            if (informacionServidor.status === 200) {
               
                const contenido = JSON.parse(informacionServidor.message);
                console.log(informacionServidor.message)
                console.log(contenido);
                // Cambio de idioma
                if (contenido.textos) {
                    setTextos(contenido.textos);
                }
                if (contenido.imagenes.length > 0) {
                    setImagenes(contenido.imagenes);
                }
                setEstudio(contenido.estudio);
                setTitulo(contenido.titulo);
                window.scrollTo(0, 0);
            }
        });
    }, [id]);

    const capatilizeString = (string) => {
        return string.trim().replace(/^\w/, (c) => c.toUpperCase());
    };
    const goToInfoGaleria = () => {
        infoGaleria === "info" ? setInfoGaleria("imagenes") : setInfoGaleria("info");
    
        const contactElement = document.getElementById(infoGaleria);
        if (contactElement) {
          contactElement.scrollIntoView({ behavior: 'smooth' });
        }
       

        
    };
    let etiquetas = etiquetasEn;
    if (localData.idioma === "es") {
        etiquetas = etiquetasEs;
    }

    return (
        <div className='pb-5'>
            {imagenes.length > 1 && (
                <>
                    <DetailsSlider imagenes={imagenes} localData={localData} estudio={estudio} titulo={titulo}/>
                </> 
            )}
            <div className="text-center">
            <div id='info' className={`${window.screen.width > 1024 ? "shrink-width" : "pad-width"} p-3`  }>
                {Object.keys(textos).map((clave) => (
                    <div
                        key={clave}
                        className={`${localData.modo === "oscuro" ? "color-letras" : "color-letras-dark"} mb-3`}
                    >
                        <div id={clave} className='div-relleno'></div>
                        <BrowserView>
                            <h2>{capatilizeString(clave)}</h2>
                        </BrowserView>
                        <MobileView>
                            <h4>{capatilizeString(clave)}</h4>
                        </MobileView>

                        <DisplayMarkdown markdownName={textos[clave]["src"]} />
                    </div>
                ))}

                <Typography
                    variant='h4'
                    style={{
                        height: "auto",
                    }}
                    className={`${localData.modo === "oscuro" ? "color-letras" : "color-letras-dark"} mb-3`}
                    // Titulo de la lista
                >
                    {capatilizeString(tituloMasInfo)}
                </Typography>
                <BrowserView>
                    <FormularioContacto localData={localData} />
                </BrowserView>
                <MobileView>
                    <FormularioMovil localData={localData} />
                </MobileView>
            </div>

            {textos && imagenes.length > 0 && (
                <Fab
                    variant='extended'
                    style={{
                        position: "fixed",
                        marginLeft: "auto",

                        right: 20,
                        bottom: 20,
                    }}
                    size={"small"}
                    className='details-fab'
                    onClick={goToInfoGaleria}
                >
                    {infoGaleria === "info" ? <ExpandMoreIcon /> : <ExpandLessIcon />}

                    {infoGaleria === "info" ? etiquetas[0] : etiquetas[1]}
                </Fab>
            )}
        </div>
        </div>
    );
}

export default DetailsPage;
