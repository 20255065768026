import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "react-bootstrap/Button";

import TextField from "@material-ui/core/TextField";
import { fetchUsuarios } from "./UsuariosFunciones";

function BotonNuevoUsuario({ info, setInfo, setSeveritySnack, setMensajeSnack }) {
    const [open, setOpen] = React.useState(false);
    const [nombreUsuario, setNombreUsuario] = React.useState("");

    const [crearDisabled, setCrearDisabled] = React.useState(true);
    // Funciones modal
    const abrirModal = () => {
        setNombreUsuario("");
        setOpen(true);
    };

    const cerrarModal = () => {
        setNombreUsuario("");
        setOpen(false);
    };

    // Funciones campo
    const isValidNombre = (nombre) => {
        let isValid = nombre !== "";
        info.datos.forEach((usuario) => {
            if (usuario.username === nombre) {
                isValid = false;
            }
        });
        setCrearDisabled(!isValid);
    };

    const cambiarNombreUsuario = (event) => {
        setNombreUsuario(event.target.value);
        isValidNombre(event.target.value);
    };

    // Creacion usuario

    const fetchCreate = async () => {
        const dataPOST = new FormData();
        dataPOST.append("username", nombreUsuario);

        const res = await fetch(`${process.env.REACT_APP_API_URL}/ahAdminFiles/usuarios/crear_usuario.php`, {
            method: "POST",
            body: dataPOST,
        });
        const data = await res.json();
        return data;
    };
    const createUsuario = () => {
        fetchCreate().then((response) => {
            if (response.status === 200) {
                fetchUsuarios().then((response) => {
                    const infoCopia = { ...info };
                    infoCopia.datos = JSON.parse(response.message);
                    infoCopia.filtrados = JSON.parse(response.message);

                    setInfo(infoCopia);

                    cerrarModal();
                });
                setSeveritySnack("success");
                setMensajeSnack("Usuario creado.");
            } else {
                setSeveritySnack("error");
                setMensajeSnack("Problema al crear al usuario.");
                cerrarModal();
            }
        });
    };

    return (
        <>
            <Button variant='primary' className='w-100' size='lg' onClick={abrirModal}>
                Nuevo usuario
            </Button>
            <Dialog
                open={open}
                onClose={cerrarModal}
                aria-labelledby='form-dialog-title'
                maxWidth='xs'
                fullWidth={true}
            >
                <DialogTitle id='form-dialog-title'>Nuevo usuario</DialogTitle>
                <DialogContent>
                    <DialogContentText>Username</DialogContentText>
                    <TextField className='w-100' color='primary' onChange={(event) => cambiarNombreUsuario(event)} />
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                    <Button size='lg' onClick={createUsuario} variant='success' disabled={crearDisabled}>
                        Crear
                    </Button>

                    <Button size='lg' onClick={cerrarModal} variant='danger'>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default BotonNuevoUsuario;
