import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "react-bootstrap/Button";
import Grid from "@material-ui/core/Grid";
import DisplayMarkdown from "../markdown/DisplayMarkdown";
import { Link } from "react-router-dom";

function MoreInformation({ open, onClose, objeto, localData }) {
    const cerrarModal = (e) => {
        if (e.classList.contains("MuiDialog-container")) {
            onClose();
        }
    };

    return (
        <div onClick={(e) => cerrarModal(e.target)}>
            <Dialog open={open} fullWidth={false} data-cerrar='si' maxWidth='md'>
                <DialogTitle
                    disableTypography
                    className={`${localData.modo === "oscuro" ? "masInfoDialogDark" : "masInfoDialogLight"} `}
                >
                    <div className={`container-fluid`}>
                        <div className='row'>
                            <div className='col'>
                                <h4>{objeto.titulo}</h4>
                            </div>
                            {objeto.detalles === 1 && (
                                <div className='col-4'>
                                    <Link to={"/project/" + objeto.contenidoid} onClick={onClose}>
                                        <Button
                                            style={{ maxWidth: "100%", wordWrap: "break-word" }}
                                            variant={`${
                                                localData.modo === "oscuro" ? "outline-light" : "outline-dark"
                                            }`}
                                            className='mas-informacion'
                                        >

                                            {window.screen.width > 600 ? (localData.idioma === "es" ? "Más información" : "More information"):"+"}
                                            
                                        </Button>
                                    </Link>
                                </div>
                            )}{" "}
                        </div>
                    </div>
                </DialogTitle>

                <DialogContent
                    className={`${localData.modo === "oscuro" ? "masInfoDialogDark" : "masInfoDialogLight "} `}
                >
                    {/* Cambiar idiomas */}
                    <DisplayMarkdown markdownName={objeto.markdown} />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default MoreInformation;
