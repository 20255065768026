export const fetchUsuarios = async () => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/ahAdminFiles/usuarios/datos_usuarios.php`);
    const data = await res.json();
    return data;
};

// Delete

export const fetchDelete = async (entrada) => {
    const dataPOST = new FormData();

    dataPOST.append("id", entrada.id);

    const res = await fetch(`${process.env.REACT_APP_API_URL}/ahAdminFiles/usuarios/eliminar_usuario.php`, {
        method: "POST",
        body: dataPOST,
    });
    const data = await res.json();
    return data;
};
export const deleteUsuario = (entrada, info, setInfo, setMensajeSnack, setSeveritySnack) => {
    const respuestaSnack = window.confirm("¿Eliminar usuario?");

    if (respuestaSnack) {
        fetchDelete(entrada).then((response) => {
            if (response.status === 200) {
                fetchUsuarios().then((response) => {
                    const infoCopia = { ...info };
                    infoCopia.datos = JSON.parse(response.message);
                    infoCopia.filtrados = JSON.parse(response.message);

                    setInfo(infoCopia);
                    setSeveritySnack("success");
                    setMensajeSnack("Usuario eliminado.");
                });
            } else {
                setSeveritySnack("error");
                setMensajeSnack("Error: usuario no eliminado.");
            }
        });
    }
};

// Modificar

export const fetchModify = async (entrada) => {
    const dataPOST = new FormData();

    dataPOST.append("datos_usuario", JSON.stringify(entrada));

    const res = await fetch(`${process.env.REACT_APP_API_URL}/ahAdminFiles/usuarios/modificar_usuario.php`, {
        method: "POST",
        body: dataPOST,
    });
    const data = await res.json();
    return data;
};
export const modificarUsuario = (entrada, info, setInfo, setMensajeSnack, setSeveritySnack) => {
    const respuestaSnack = window.confirm("¿Modificar usuario?");

    if (respuestaSnack) {
        fetchModify(entrada).then((response) => {
            if (response.status === 200) {
                fetchUsuarios().then((response) => {
                    const infoCopia = { ...info };
                    infoCopia.datos = JSON.parse(response.message);
                    infoCopia.filtrados = JSON.parse(response.message);

                    setInfo(infoCopia);
                    setSeveritySnack("success");
                    setMensajeSnack("Usuario modificado.");
                });
            } else if (response.status === 400) {
                setSeveritySnack("error");
                setMensajeSnack("Error: Nombre de usuario en uso.");
            } else {
                setSeveritySnack("error");
                setMensajeSnack("Error: usuario no modificado.");
            }
        });
    }
};

export const filtrar = (filtrado, info, setInfo, opciones, setOpciones) => {
    const infoCopia = { ...info };

    const datosValidos = [];
    info.datos.forEach((entrada) => {
        let isValidEntrada = true;
        if (filtrado.texto !== "") {
            isValidEntrada = false;
            Object.keys(entrada).forEach((clave) => {
                if (typeof entrada[clave] === "string") {
                    isValidEntrada = isValidEntrada || entrada[clave].toLowerCase().includes(filtrado.texto);
                }
            });
        }
        if (filtrado.rol !== "") {
            isValidEntrada = isValidEntrada && entrada["rol"] === filtrado.rol;
        }

        if (isValidEntrada === true) {
            datosValidos.push(entrada);
        }
    });
    infoCopia.filtrados = datosValidos;
    setInfo(infoCopia);

    const opcionesCopia = { ...opciones };
    opcionesCopia.pagina = 0;

    setOpciones(opcionesCopia);
};
