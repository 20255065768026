import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "react-bootstrap/Button";
import { useEffect } from "react";

import DialogCampos from "../comunes/componentes/opcionesTablas/DialogoCampos";
import OpcionesFilas from "../comunes/componentes/opcionesTablas/OpcionesFilas";
import PaginacionTabla from "../comunes/componentes/opcionesTablas/PaginacionTabla";

import BotonNuevaEntrada from "../comunes/componentes/filtros/BotonNuevaEntrada";
import AutoCompleteFiltro from "../comunes/componentes/filtros/AutoCompleteFiltro";
import FiltroTexto from "../comunes/componentes/filtros/FiltroTexto";

import TextInput from "../comunes/componentes/inputs/TextInput";
import AutocompleteInput from "../comunes/componentes/inputs/AutocompleteInput";
import ControlesFicheros from "../comunes/componentes/inputs/ControlesFicheros";

import { fetchContent, fetchOpcionesFormularios } from "../comunes/funciones/PeticionesServidor";
import { getDatosEstructurales, getDatosFiltrado, getDatosOpcionesTabla } from "../comunes/funciones/DatosPaneles";
import { filtrarDatos, getOpcionesFormDinamic, getIndexEntrada } from "../comunes/funciones/FilasFunciones";

function DetallesTab() {
    const [info, setInfo] = React.useState(getDatosEstructurales("detalles", "contenidoid"));
    const [filtrado, setFiltrado] = React.useState(
        getDatosFiltrado(["agrupacionid", "subagrupacionid", "contenidoid"])
    );
    const [opciones, setOpciones] = React.useState(getDatosOpcionesTabla());

    /**Fetch de datos */

    useEffect(() => {
        fetchContent(info.tipo).then((informacionServidor) => {
            const infoCopia = { ...info };
            infoCopia.datos = JSON.parse(informacionServidor.message);
            infoCopia.filtrados = JSON.parse(informacionServidor.message);

            setInfo(infoCopia);
        });
        fetchOpcionesFormularios().then((informacionServidor) => {
            const filtradoCopia = { ...filtrado };
            filtradoCopia.filtros.opciones = JSON.parse(informacionServidor.message);
            setFiltrado(filtradoCopia);
        });
    }, []);

    /** Cambiar Select */
    const handleCambiarSelect = (event, entrada, campo) => {
        const indiceFiltrado = getIndexEntrada(entrada, info.filtrados);
        const indiceDatos = getIndexEntrada(entrada, info.datos);

        entrada[campo] = event.target.value;

        if (event.target.value === "imagen") {
            entrada["ingles"] = "2";
        }

        const infoCopia = { ...info };
        infoCopia.datos[indiceDatos] = entrada;
        infoCopia.filtrados[indiceFiltrado] = entrada;

        setInfo(infoCopia);
    };

    const isValidMover = (entrada) => {
        if (opciones.posicionMover !== -1) {
            const valorContenidoId = info.filtrados[opciones.posicionMover].contenidoid;

            return valorContenidoId === entrada.contenidoid;
        }
        return false;
    };
    return (
        <div>
            <div className='row mt-3 mb-3 '>
                <div className='col-sm-2 '>
                    <AutoCompleteFiltro
                        filtrado={filtrado}
                        setFiltrado={setFiltrado}
                        campo='agrupacionid'
                        opciones={filtrado.filtros.opciones["agrupacionid"]}
                    />
                </div>
                <div className='col-sm-2 '>
                    <AutoCompleteFiltro
                        filtrado={filtrado}
                        setFiltrado={setFiltrado}
                        campo='subagrupacionid'
                        opciones={getOpcionesFormDinamic(filtrado, "agrupacionid", "subagrupacionid")}
                    />
                </div>
                <div className='col-sm-2 '>
                    <AutoCompleteFiltro
                        filtrado={filtrado}
                        setFiltrado={setFiltrado}
                        campo='contenidoid'
                        opciones={getOpcionesFormDinamic(filtrado, "subagrupacionid", "contenidoid")}
                    />
                </div>
                <div className='col-sm-2 '>
                    <FiltroTexto filtrado={filtrado} setFiltrado={setFiltrado} />
                </div>
                <div className='col-sm-2'>
                    <Button
                        variant='secondary'
                        onClick={() => filtrarDatos(info, setInfo, opciones, setOpciones, filtrado)}
                        className='w-100'
                        size='lg'
                    >
                        Filtrar
                    </Button>
                </div>

                <div className='col-sm-2'>
                    <BotonNuevaEntrada
                        info={info}
                        setInfo={setInfo}
                        opciones={opciones}
                        setOpciones={setOpciones}
                        filtrado={filtrado}
                        /** Si hay seleccionado un agrupacionid solo dejamos ese */
                        tiposEntrada={getOpcionesFormDinamic(filtrado, "subagrupacionid", "contenidoid")}
                    />
                </div>
            </div>
            <Paper>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Contenido ID</TableCell>
                                <TableCell>Tipo</TableCell>
                                <TableCell>Titulo</TableCell>
                                <TableCell>Fichero</TableCell>
                                <TableCell>Idioma</TableCell>
                                <TableCell>Mover</TableCell>
                                <TableCell>Guardar</TableCell>
                                <TableCell>Eliminar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {info.filtrados
                                .slice(
                                    opciones.pagina * opciones.filasPagina,
                                    opciones.pagina * opciones.filasPagina + opciones.filasPagina
                                )
                                .map((entrada, index) => {
                                    return (
                                        <TableRow
                                            key={index + "_" + entrada.id}
                                            className={`${isValidMover(entrada) ? "bg-success" : ""}`}
                                        >
                                            <TableCell>
                                                <AutocompleteInput
                                                    entrada={entrada}
                                                    campo='contenidoid'
                                                    opciones={getOpcionesFormDinamic(
                                                        filtrado,
                                                        "subagrupacionid",
                                                        "contenidoid"
                                                    )}
                                                    info={info}
                                                    setInfo={setInfo}
                                                    filtrado={filtrado}
                                                    setFiltrado={setFiltrado}
                                                    setOpciones={setOpciones}
                                                />
                                            </TableCell>

                                            <TableCell>
                                                <Select
                                                    value={entrada.tipo}
                                                    onChange={(event) => handleCambiarSelect(event, entrada, "tipo")}
                                                >
                                                    <MenuItem value={"imagen"}>Imagen</MenuItem>
                                                    <MenuItem value={"texto"}>Texto</MenuItem>
                                                </Select>
                                            </TableCell>

                                            <TableCell>
                                                {entrada.tipo === "texto" && (
                                                    <TextInput
                                                        info={info}
                                                        setInfo={setInfo}
                                                        entrada={entrada}
                                                        campo='titulo'
                                                        opciones={opciones}
                                                        setOpciones={setOpciones}
                                                    />
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <ControlesFicheros
                                                    entrada={entrada}
                                                    campo='src'
                                                    info={info}
                                                    setInfo={setInfo}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Select
                                                    disabled={entrada.tipo === "imagen" ? true : false}
                                                    value={entrada.ingles}
                                                    onChange={(event) => handleCambiarSelect(event, entrada, "ingles")}
                                                >
                                                    <MenuItem value={"0"}>Español</MenuItem>
                                                    <MenuItem value={"1"}>Inglés</MenuItem>
                                                    <MenuItem value={"2"}>Ambos</MenuItem>
                                                </Select>
                                            </TableCell>
                                            <OpcionesFilas
                                                info={info}
                                                setInfo={setInfo}
                                                opciones={opciones}
                                                setOpciones={setOpciones}
                                                entrada={entrada}
                                                filtrado={filtrado}
                                            />
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginacionTabla info={info} opciones={opciones} setOpciones={setOpciones} />
            </Paper>
            <DialogCampos
                info={info}
                setInfo={setInfo}
                datosModal={opciones.datosModal}
                opciones={opciones}
                setOpciones={setOpciones}
            />
        </div>
    );
}

export default DetallesTab;
