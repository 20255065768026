import React from "react";
import Button from "react-bootstrap/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DisplayMarkdown from "../markdown/DisplayMarkdown";
function DialogInformacion({ onClose, mdDialog, localData }) {
    return (
        <div>
            <Dialog open={mdDialog !== ""} onClose={() => onClose()} maxWidth='lg'>
                <DialogContent
                    className={`${localData.modo === "oscuro" ? "masInfoDialogDark" : "masInfoDialogLight"}`}
                >
                    <DisplayMarkdown markdownName={mdDialog} />
                </DialogContent>

                <DialogActions
                    className={`${localData.modo === "oscuro" ? "masInfoDialogDark" : "masInfoDialogLight"}`}
                >
                    <Button className='w-100' onClick={() => onClose()} variant={`outline-dark`}>
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DialogInformacion;
