import React from "react";
import Button from "react-bootstrap/Button";

export default function RenderLinks(props) {
    // Revisar más adelante cuando quiera varios tipos de botones y demas

    if (!("children" in props) || props.children.length === 0) {
        return <></>;
    }
    let typeOfLink = "normal";

    const variablesLink = props.children[0].props.value.split("_");
    const textoLink = variablesLink[0];

    // Revisar
    if (variablesLink.length > 1) {
        const tipo = variablesLink[1];
        if (typeOfLink !== tipo) {
            typeOfLink = tipo;
        }
    }

    // Para las urls de youtube

    const cambiarUrlVideos = (url) => {
        return url
            .replace("https://youtu.be", "https://www.youtube.com/embed")
            .replace("https://www.youtube.com/watch", "https://www.youtube.com/embed");
    };

    const cambiarUrlLocal = (url) => {
        if (!url.includes("http")) {
            url = process.env.REACT_APP_SRC_URL + url;
            if (url.indexOf("//") > -1) {
                url = url.replace("//", "/");
            }
        }
        return url;
    };

    let variant = "dark";
    if (typeOfLink === "button") {
        const variantes = ["primary", "secondary", "success", "warning", "danger", "info", "light", "link", "dark"];

        variantes.forEach((variante) => {
            if (variablesLink.indexOf(variante) > -1) {
                variant = variante;
            }
        });

        if (variablesLink.indexOf("out") > -1) {
            variant = "outline-" + variant;
        }
    }

    return (
        <>
            {typeOfLink === "button" && (
                <Button variant={variant} size='sm' block href={cambiarUrlLocal(props.href)} target='_blank'>
                    {textoLink}
                </Button>
            )}
            {typeOfLink === "imagen" && (
                <div className='text-center' style={{ maxWidth: "100%" }}>
                    <img src={cambiarUrlLocal(props.href)} alt={textoLink} clasName='markdown-image' />
                </div>
            )}
            {typeOfLink === "video" && (
                <div className='text-center' style={{ maxWidth: "100%" }}>
                    <iframe
                        width={variablesLink[2]}
                        height={variablesLink[3]}
                        src={cambiarUrlVideos(props.href)}
                        style={{ border: "0", maxWidth: "100%" }}
                        title={variablesLink[0]}
                        frameborder='0'
                        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        allowFullScreen
                    ></iframe>
                </div>
            )}

            {typeOfLink === "normal" && (
                <a href={props.href} target='_blank' rel='noreferrer'>
                    {textoLink}
                </a>
            )}
            {typeOfLink === "mapa" && (
                <div className='text-center' style={{ maxWidth: "100%" }}>
                    <iframe
                        title={variablesLink[0]}
                        src={props.href}
                        width={variablesLink[2]}
                        height={variablesLink[3]}
                        style={{ border: "0", maxWidth: "100%" }}
                        allowFullScreen
                        loading='lazy'
                    ></iframe>
                </div>
            )}
        </>
    );
}
