import React from "react";
import Button from "react-bootstrap/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ReactCountryFlag from "react-country-flag";

const etiquetasEs = ["Cambiar de pais", "España", "Qatar", "Cancelar", "Confirmar"];
const etiquetasEn = ["Change of country", "Spain", "Qatar", "Cancel", "Confirm"];

function DialogPais({ open, onClose, cambiarPais, localData }) {
    const etiquetas = localData.idioma === "es" ? etiquetasEs : etiquetasEn;

    const [pais, setPais] = React.useState(localData.pais);

    const changePais = (event) => {
        setPais(event.target.value);
    };
    const aceptarCambio = () => {
        cambiarPais(pais);
        onClose();
    };

    return (
        <div>
            <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={onClose}>
                <DialogTitle className={`${localData.modo === "oscuro" ? "masInfoDialogDark" : "masInfoDialogLight"}`}>
                    {etiquetas[0]}
                </DialogTitle>
                <DialogContent
                    className={`${localData.modo === "oscuro" ? "masInfoDialogDark" : "masInfoDialogLight"}`}
                >
                    <Select
                        value={pais}
                        onChange={(event) => changePais(event)}
                        fullWidth
                        MenuProps={{
                            MenuListProps: {
                                className: `${
                                    localData.modo === "oscuro" ? "masInfoDialogDark" : "masInfoDialogLight"
                                }`,
                            },
                        }}
                    >
                        <MenuItem value='es'>
                            <ReactCountryFlag className='mr-2' countryCode={"es"} svg />
                            <span className={`${localData.modo === "oscuro" ? "color-letras" : "color-letras-dark"}`}>
                                {etiquetas[1]}
                            </span>
                        </MenuItem>
                        <MenuItem value='qa'>
                            <ReactCountryFlag className='mr-2' countryCode={"qa"} svg />
                            <span className={`${localData.modo === "oscuro" ? "color-letras" : "color-letras-dark"}`}>
                                {etiquetas[2]}
                            </span>
                        </MenuItem>
                    </Select>
                </DialogContent>
                <DialogActions
                    className={`${localData.modo === "oscuro" ? "masInfoDialogDark" : "masInfoDialogLight"}`}
                >
                    <Button onClick={onClose} variant='danger'>
                        {etiquetas[3]}
                    </Button>
                    <Button onClick={aceptarCambio} variant='success'>
                        {etiquetas[4]}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DialogPais;
