import React from "react";

import TextField from "@material-ui/core/TextField";
import { useEffect } from "react";

import DialogInformacion from "../apoyo/DialogInformacion";
import Button from "react-bootstrap/Button";
import { getDefaultAuthData, fetchLogout } from "../../functions/AuthFunctions";

const labelES = [
    "Usuario",
    "Rol del usuario",
    "Contraseña nueva",
    "*La contraseña debe tener al menos 8 caracteres",
    "Repetir  contraseña",
    "*Las contraseñas deben ser iguales",
    "Contraseña anterior",
    "Cambiar",
    "Cerrar sesión",
];
const labelEN = [
    "Username",
    "User rol",
    "New password",
    "*The password must have at least 8 characters",
    "Repeat password",
    "*The passwords must be equal",
    "Old password",
    "Change",
    "Log out",
];

function DatosUsuario({ localData }) {
    const etiquetas = localData.idioma === "es" ? labelES : labelEN;

    const [mdDialog, setMdDialog] = React.useState("");
    const [dataUserLogged, setDataUserLogged] = React.useState(getDefaultAuthData());

    const [password, setPassword] = React.useState("");

    const [newPass, setNewPass] = React.useState("");
    const [repeatPass, setRepeatPass] = React.useState("");

    useEffect(() => {
        // document.getElementById("logobtn").click();

        if (window.sessionStorage.getItem("auth") !== null) {
            const authData = JSON.parse(window.sessionStorage.getItem("auth"));
            setDataUserLogged(authData);
        } else {
            window.open("/login", "_top");
        }
    }, []);

    const handleChangePass = (event) => {
        setPassword(event.target.value);
    };

    const handleChangeNewPass = (event) => {
        setNewPass(event.target.value);
    };

    const handleChangeRepeatPass = (event) => {
        setRepeatPass(event.target.value);
    };

    const fetchModificarUsuario = async () => {
        const dataPOST = new FormData();

        const datosUsuario = { usuario: dataUserLogged.usuario, password, newPass };

        dataPOST.append("datos_usuario", JSON.stringify(datosUsuario));
        const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/modificar_usuario.php`, {
            method: "POST",
            body: dataPOST,
        });
        const data = await res.json();
        return data;
    };

    const handleClickModificar = () => {
        if (repeatPass === newPass && newPass.length > 7 && password !== "") {
            fetchModificarUsuario().then((response) => {
                setMdDialog(`documentos/dialogos/usuario/${response.status}_${localData.idioma}.md`);
            });
        }
    };

    const handleClickLogOut = () => {
        fetchLogout().then((response) => {
            if (response.status === 200) {
                sessionStorage.removeItem("auth");
                setDataUserLogged(getDefaultAuthData());
                window.location.reload();
            } else {
                alert("Ha habido un error en el logout");
            }
        });
    };

    return (
        <>
            <div
                className={`container-fluid h-100 menuLogin ${
                    localData.modo === "oscuro" ? "menuLoginDark" : "menuLoginLight"
                }`}
            >
                <form validate autoComplete='off' className='text-center '>
                    <div className='mt-3'>
                        <TextField
                            disabled
                            variant='filled'
                            label={etiquetas[0]}
                            className='w-75 text-light'
                            value={dataUserLogged.usuario}
                        />
                    </div>
                    <div className='mt-3'>
                        <TextField
                            disabled
                            variant='filled'
                            label={etiquetas[1]}
                            className='w-75 text-light'
                            value={dataUserLogged.rol}
                        />
                    </div>
                    <div className='mt-3'>
                        <TextField
                            type='password'
                            label={etiquetas[2]}
                            className='w-75'
                            onChange={(event) => {
                                handleChangeNewPass(event);
                            }}
                            error={newPass.length < 8 && newPass !== ""}
                            helperText={newPass.length < 8 ? etiquetas[3] : ""}
                        />
                    </div>
                    <div className='mt-3'>
                        <TextField
                            type='password'
                            disabled={newPass.length < 8}
                            label={etiquetas[4]}
                            className='w-75'
                            onChange={(event) => {
                                handleChangeRepeatPass(event);
                            }}
                            error={repeatPass !== newPass && newPass.length > 7}
                            helperText={repeatPass !== newPass ? etiquetas[5] : ""}
                        />
                    </div>
                    <div className='mt-3'>
                        <TextField
                            type='password'
                            disabled={repeatPass !== newPass || newPass.length < 8}
                            label={etiquetas[6]}
                            className='w-75'
                            onChange={(event) => {
                                handleChangePass(event);
                            }}
                        />
                    </div>
                    <div className='mt-3'>
                        <Button
                            className='w-75'
                            variant='dark'
                            disabled={repeatPass !== newPass || newPass.length < 8 || password === ""}
                            onClick={handleClickModificar}
                        >
                            {etiquetas[7]}
                        </Button>
                    </div>
                    <div className='mt-3'>
                        <Button className='w-75' variant='danger' onClick={handleClickLogOut}>
                            {etiquetas[8]}
                        </Button>
                    </div>
                </form>
            </div>
            <DialogInformacion onClose={() => setMdDialog("")} mdDialog={mdDialog} localData={localData} />
        </>
    );
}

export default DatosUsuario;
