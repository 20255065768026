import React from "react";
import ReactDOM from "react-dom";
import "./css/fonts/fonts.css";
import "./css/index.css";
import "./css/toolbar.css";
import "./css/markdown.css";
import "./css/slide.css";
import "./css/admin.css";
import "./css/portada.css";
import "./css/formulario.css";
import "./css/modificados-material.css";
import "./css/contenidos.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
