import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
import { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Select from "@material-ui/core/Select";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";

import PaginacionTabla from "../comunes/componentes/opcionesTablas/PaginacionTabla";
import TextInput from "../comunes/componentes/inputs/TextInput";
import DialogCampos from "../comunes/componentes/opcionesTablas/DialogoCampos";

import PasswordInput from "./PasswordInput";
import BotonNuevoUsuario from "./BotonNuevoUsuario";

import { getDatosEstructurales, getDatosOpcionesTabla } from "../comunes/funciones/DatosPaneles";
import { fetchUsuarios, filtrar, deleteUsuario, modificarUsuario } from "./UsuariosFunciones";
import { getIndexEntrada } from "../comunes/funciones/FilasFunciones";

function UsuariosPage() {
    const [info, setInfo] = useState(getDatosEstructurales("usuarios", ""));
    const [opciones, setOpciones] = useState(getDatosOpcionesTabla());
    const [mensajeSnack, setMensajeSnack] = useState("");
    const [severitySnack, setSeveritySnack] = useState("success");

    const [filtrado, setFiltrado] = useState({ texto: "", rol: "" });

    useEffect(() => {
        fetchUsuarios().then((informacionServidor) => {
            const infoCopia = { ...info };
            infoCopia.datos = JSON.parse(informacionServidor.message);
            infoCopia.filtrados = JSON.parse(informacionServidor.message);

            setInfo(infoCopia);
        });
    }, []);

    // Cosas filtrado
    const cambiarFiltroTexto = (event) => {
        const filtradoCopia = { ...filtrado };
        filtradoCopia.texto = event.target.value;

        setFiltrado(filtradoCopia);
    };

    const buscarRol = (valor) => {
        const filtradoCopia = { ...filtrado };
        filtradoCopia.rol = valor;

        setFiltrado(filtradoCopia);
    };
    const cambiarRolFiltro = (event) => {
        buscarRol(event.target.value);
    };

    // Cambiar campos
    const cambiarRol = (event, entrada) => {
        const indiceFiltrado = getIndexEntrada(entrada, info.filtrados);
        const indiceDatos = getIndexEntrada(entrada, info.datos);

        entrada.rol = event.target.value;

        const infoCopia = { ...info };
        infoCopia.datos[indiceDatos] = entrada;
        infoCopia.filtrados[indiceFiltrado] = entrada;

        setInfo(infoCopia);
    };

    return (
        <div>
            <div className='row mt-3 mb-3 '>
                <div className='col-sm-5 '>
                    <TextField
                        variant='filled'
                        color='secondary'
                        fullWidth
                        value={filtrado.texto}
                        label='Clave para filtrado'
                        onChange={cambiarFiltroTexto}
                    />
                </div>
                <div className='col-sm-3'>
                    <FormControl fullWidth color='secondary'>
                        <InputLabel>Rol</InputLabel>
                        <Select
                            variant='filled'
                            color='secondary'
                            fullWidth
                            value={filtrado.rol}
                            onChange={(event) => cambiarRolFiltro(event)}
                        >
                            <MenuItem value='admin'>Admin</MenuItem>
                            <MenuItem value='trabajador'>Trabajador</MenuItem>
                            <MenuItem value='proveedor'>Proveedor</MenuItem>
                            <MenuItem value='cliente'>Cliente</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className='col-sm-2'>
                    <Button
                        variant='secondary'
                        className='w-100'
                        size='lg'
                        onClick={() => filtrar(filtrado, info, setInfo, opciones, setOpciones)}
                    >
                        Filtrar
                    </Button>
                </div>
                <div className='col-sm-2'>
                    <BotonNuevoUsuario
                        info={info}
                        setInfo={setInfo}
                        setSeveritySnack={setSeveritySnack}
                        setMensajeSnack={setMensajeSnack}
                    />
                </div>
            </div>
            <Paper>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre de usuario</TableCell>
                                <TableCell>Rol</TableCell>
                                <TableCell>Contraseña</TableCell>
                                <TableCell>Guardar</TableCell>
                                <TableCell>Eliminar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {info.filtrados
                                .slice(
                                    opciones.pagina * opciones.filasPagina,
                                    opciones.pagina * opciones.filasPagina + opciones.filasPagina
                                )
                                .map((entrada, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <TextInput
                                                    info={info}
                                                    setInfo={setInfo}
                                                    entrada={entrada}
                                                    campo='username'
                                                    opciones={opciones}
                                                    setOpciones={setOpciones}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <div className='row'>
                                                    <div className='col'>
                                                        <Select
                                                            fullWidth
                                                            value={entrada.rol}
                                                            onChange={(event) => cambiarRol(event, entrada)}
                                                        >
                                                            <MenuItem value='admin'>Admin</MenuItem>
                                                            <MenuItem value='trabajador'>Trabajador</MenuItem>
                                                            <MenuItem value='proveedor'>Proveedor</MenuItem>
                                                            <MenuItem value='cliente'>Cliente</MenuItem>
                                                        </Select>
                                                    </div>
                                                    <div className='col-2'>
                                                        <Tooltip
                                                            title='Poner clave en buscador'
                                                            onClick={() => buscarRol(entrada.rol)}
                                                        >
                                                            <IconButton>
                                                                <SearchIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <PasswordInput
                                                    info={info}
                                                    setInfo={setInfo}
                                                    entrada={entrada}
                                                    campo='pass'
                                                />
                                            </TableCell>
                                            <TableCell
                                                onClick={() =>
                                                    modificarUsuario(
                                                        entrada,
                                                        info,
                                                        setInfo,
                                                        setMensajeSnack,
                                                        setSeveritySnack
                                                    )
                                                }
                                            >
                                                <IconButton color='primary' size='small'>
                                                    <SaveIcon />
                                                </IconButton>
                                            </TableCell>
                                            <TableCell>
                                                <TableCell
                                                    onClick={() =>
                                                        deleteUsuario(
                                                            entrada,
                                                            info,
                                                            setInfo,
                                                            setMensajeSnack,
                                                            setSeveritySnack
                                                        )
                                                    }
                                                >
                                                    <IconButton color='secondary' size='small'>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <PaginacionTabla info={info} opciones={opciones} setOpciones={setOpciones} />
            </Paper>
            <DialogCampos
                info={info}
                setInfo={setInfo}
                datosModal={opciones.datosModal}
                opciones={opciones}
                setOpciones={setOpciones}
            />
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={mensajeSnack !== ""}
                onClose={() => setMensajeSnack("")}
                autoHideDuration={5000}
            >
                <MuiAlert elevation={6} variant='filled' onClose={() => setMensajeSnack("")} severity={severitySnack}>
                    {mensajeSnack}
                </MuiAlert>
            </Snackbar>
        </div>
    );
}

export default UsuariosPage;
