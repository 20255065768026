import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { getIndexEntrada } from "../../funciones/FilasFunciones";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";

function AutocompleteInput({ entrada, campo, opciones, setOpciones, info, setInfo, filtrado, setFiltrado }) {
    const getEntradasCambiarOrden = (valor) => {
        const infoCopia = { ...info };
        const listaEntradasRestar = [];

        let ordenEntradaNuevo = 0;
        let entradaCambiar = { orden: ordenEntradaNuevo };

        const valorAnterior = entrada[campo];
        const ordenAnterior = parseInt(entrada["orden"]);

        infoCopia.datos.forEach((entradaDatos, index) => {
            const ordenEntrada = parseInt(entradaDatos["orden"]);
            if (entradaDatos[campo] === valorAnterior && ordenEntrada > ordenAnterior) {
                listaEntradasRestar.push(entradaDatos);
            }

            if (entradaDatos[campo] === valor && ordenEntrada >= ordenEntradaNuevo) {
                ordenEntradaNuevo = ordenEntrada + 1;
                entradaCambiar = entradaDatos;
            }
        });

        return { listaEntradasRestar, entradaCambiar };
    };

    const cambiarCampo = (valorNuevo) => {
        const indiceFiltrado = getIndexEntrada(entrada, info.filtrados);
        const indiceDatos = getIndexEntrada(entrada, info.datos);

        const infoCopia = { ...info };

        // Sacar las entradas cuyo orden debe cambiar
        const datosCambiar = getEntradasCambiarOrden(valorNuevo);

        // Recorrer las entradas e ir cambiando los ordenes en filtrados y datos
        datosCambiar.listaEntradasRestar.forEach((entradaRestar) => {
            const indiceRestarFiltrado = getIndexEntrada(entrada, info.filtrados);
            const indiceRestarDatos = getIndexEntrada(entrada, info.datos);

            const ordenAnterior = parseInt(infoCopia.filtrados[indiceRestarFiltrado]["orden"]);

            infoCopia.filtrados[indiceRestarFiltrado]["orden"] = (ordenAnterior - 1).toString();
            infoCopia.datos[indiceRestarDatos]["orden"] = (ordenAnterior - 1).toString();
        });

        // Cambiar la posicion de la entrada y cambiar su valor de orden

        entrada[campo] = valorNuevo;
        entrada["orden"] = parseInt(datosCambiar.entradaCambiar["orden"] + 1).toString();

        infoCopia.datos[indiceDatos] = entrada;
        infoCopia.filtrados[indiceFiltrado] = entrada;

        setInfo(infoCopia);
    };
    const buscarClave = () => {
        const filtradoCopia = { ...filtrado };
        filtradoCopia.filtros.valores[campo] = entrada[campo];
        setFiltrado(filtradoCopia);
    };

    return (
        <div className='row'>
            <div className='col'>
                <Autocomplete
                    onChange={(event, newValue) => cambiarCampo(newValue)}
                    options={opciones.map((option) => option)}
                    style={{ minWidth: 150 }}
                    value={entrada[campo]}
                    renderInput={(params) => <TextField {...params} label={campo} />}
                />
            </div>
            {campo === info.campo && (
                <div className='col-2'>
                    <Tooltip title='Poner clave en buscador' onClick={buscarClave}>
                        <IconButton>
                            <SearchIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            )}
        </div>
    );
}

export default AutocompleteInput;
