import React from "react";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "react-bootstrap/Button";
import { useEffect } from "react";

import DialogCampos from "../comunes/componentes/opcionesTablas/DialogoCampos";
import OpcionesFilas from "../comunes/componentes/opcionesTablas/OpcionesFilas";
import PaginacionTabla from "../comunes/componentes/opcionesTablas/PaginacionTabla";

import FiltroTexto from "../comunes/componentes/filtros/FiltroTexto";
import BotonNuevaEntrada from "../comunes/componentes/filtros/BotonNuevaEntrada";
import AutoCompleteFiltro from "../comunes/componentes/filtros/AutoCompleteFiltro";

import ReadOnlyTextInput from "../comunes/componentes/inputs/ReadOnlyTextInput";
import TextInput from "../comunes/componentes/inputs/TextInput";
import AutocompleteInput from "../comunes/componentes/inputs/AutocompleteInput";

import { fetchContent, fetchOpcionesFormularios } from "../comunes/funciones/PeticionesServidor";
import { getDatosEstructurales, getDatosFiltrado, getDatosOpcionesTabla } from "../comunes/funciones/DatosPaneles";
import { filtrarDatos } from "../comunes/funciones/FilasFunciones";

function SubgrupacionesTab() {
    const [info, setInfo] = React.useState(getDatosEstructurales("subagrupaciones", "agrupacionid"));
    const [filtrado, setFiltrado] = React.useState(getDatosFiltrado(["agrupacionid"]));
    const [opciones, setOpciones] = React.useState(getDatosOpcionesTabla());

    /**Fetch de datos */

    useEffect(() => {
        fetchContent(info.tipo).then((informacionServidor) => {
            const infoCopia = { ...info };
            infoCopia.datos = JSON.parse(informacionServidor.message);
            infoCopia.filtrados = JSON.parse(informacionServidor.message);

            setInfo(infoCopia);
        });
        fetchOpcionesFormularios().then((informacionServidor) => {
            const filtradoCopia = { ...filtrado };
            filtradoCopia.filtros.opciones = JSON.parse(informacionServidor.message);
            setFiltrado(filtradoCopia);
        });
    }, []);

    const isValidMover = (entrada) => {
        if (opciones.posicionMover !== -1) {
            const valorAgrupacionId = info.filtrados[opciones.posicionMover].agrupacionid;

            return valorAgrupacionId === entrada.agrupacionid;
        }
        return false;
    };

    return (
        <div>
            <div className='row mt-3 mb-3 '>
                <div className='col-sm-2 '>
                    <AutoCompleteFiltro
                        filtrado={filtrado}
                        setFiltrado={setFiltrado}
                        campo='agrupacionid'
                        opciones={filtrado.filtros.opciones["agrupacionid"]}
                    />
                </div>
                <div className='col-sm-6 '>
                    <FiltroTexto filtrado={filtrado} setFiltrado={setFiltrado} />
                </div>
                <div className='col-sm-2'>
                    <Button
                        variant='secondary'
                        onClick={() => filtrarDatos(info, setInfo, opciones, setOpciones, filtrado)}
                        className='w-100'
                        size='lg'
                    >
                        Filtrar
                    </Button>
                </div>
                <div className='col-sm-2'>
                    <BotonNuevaEntrada
                        info={info}
                        setInfo={setInfo}
                        opciones={opciones}
                        setOpciones={setOpciones}
                        filtrado={filtrado}
                        tiposEntrada={filtrado.filtros.opciones.agrupacionid}
                    />
                </div>
            </div>
            <Paper>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Agrupacion Id</TableCell>
                                <TableCell>Identificador</TableCell>
                                <TableCell>Titulo Español</TableCell>
                                <TableCell>Titulo Inglés</TableCell>
                                <TableCell>Mover</TableCell>
                                <TableCell>Guardar</TableCell>
                                <TableCell>Eliminar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {info.filtrados
                                .slice(
                                    opciones.pagina * opciones.filasPagina,
                                    opciones.pagina * opciones.filasPagina + opciones.filasPagina
                                )
                                .map((entrada, index) => {
                                    return (
                                        <TableRow
                                            key={index}
                                            className={`${isValidMover(entrada) ? "bg-success" : ""}`}
                                            /** Meter colorines */
                                        >
                                            <TableCell>
                                                <AutocompleteInput
                                                    entrada={entrada}
                                                    campo='agrupacionid'
                                                    opciones={filtrado.filtros.opciones["agrupacionid"]}
                                                    info={info}
                                                    setInfo={setInfo}
                                                    filtrado={filtrado}
                                                    setFiltrado={setFiltrado}
                                                    setOpciones={setOpciones}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <ReadOnlyTextInput
                                                    info={info}
                                                    setInfo={setInfo}
                                                    entrada={entrada}
                                                    campo='id_str'
                                                    opciones={opciones}
                                                    setOpciones={setOpciones}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextInput
                                                    info={info}
                                                    setInfo={setInfo}
                                                    entrada={entrada}
                                                    campo='titulo_es'
                                                    opciones={opciones}
                                                    setOpciones={setOpciones}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <TextInput
                                                    info={info}
                                                    setInfo={setInfo}
                                                    entrada={entrada}
                                                    campo='titulo_en'
                                                    opciones={opciones}
                                                    setOpciones={setOpciones}
                                                />
                                            </TableCell>
                                            <OpcionesFilas
                                                info={info}
                                                setInfo={setInfo}
                                                opciones={opciones}
                                                setOpciones={setOpciones}
                                                entrada={entrada}
                                                filtrado={filtrado}
                                            />
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginacionTabla info={info} opciones={opciones} setOpciones={setOpciones} />
            </Paper>
            <DialogCampos
                info={info}
                setInfo={setInfo}
                datosModal={opciones.datosModal}
                opciones={opciones}
                setOpciones={setOpciones}
            />
        </div>
    );
}

export default SubgrupacionesTab;
