import detectBrowserLanguage from "detect-browser-language";
export const SI_POLI = "si";
export const NO_POLI = "no";

// Datos locales del usuario
export const getIdiomaBrowser = () => {
    if (detectBrowserLanguage().substring(0, 2) === "es") {
        return "es";
    }
    return "en";
};

export const getDefaultLocalData = () => {
    if (window.localStorage.getItem("localData") !== null) {
        return JSON.parse(window.localStorage.getItem("localData"));
    }

    return {
        politica: NO_POLI,
        modo: "oscuro",
        idioma: getIdiomaBrowser(),
        pais: "es",
    };
};

export const changeLocalData = (localData, setLocalData, campo, valor) => {
    const localDataCopia = { ...localData };
    localDataCopia[campo] = valor;
    setLocalData(localDataCopia);
    window.localStorage.setItem("localData", JSON.stringify(localDataCopia));
};

// Peticiones a la web
export const fetchCambiarIdioma = async (idioma) => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/cambiar_idioma.php?idioma=${idioma}`);
    const data = await res.json();
    return data;
};

export const fetchCambiarPais = async (pais) => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/cambiar_pais.php?pais=${pais}`);
    const data = await res.json();
    return data;
};
