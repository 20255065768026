import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import Button from "react-bootstrap/Button";
import { getIndexEntrada, limpiarString } from "../../funciones/FilasFunciones";

function DialogoCampos({ info, setInfo, datosModal, opciones, setOpciones }) {
    const cerrarModal = (e) => {
        if (e.classList.contains("MuiDialog-container") || e.classList.contains("btn")) {
            const opcionesCopia = { ...opciones };
            opcionesCopia.datosModal = { entrada: null, campo: null };
            setOpciones(opcionesCopia);
        }
    };

    const cambiarValor = (e) => {
        const entrada = datosModal.entrada;
        const indiceDatos = getIndexEntrada(entrada, info.datos);
        const indiceFiltrado = getIndexEntrada(entrada, info.filtrados);

        entrada[datosModal.campo] = e.target.value;
        if (datosModal.campo === "titulo_en" && "id_str" in entrada) {
            entrada["id_str"] = limpiarString(entrada[datosModal.campo]);
        } else if (datosModal.campo === "titulo_en" && "contenidoid" in entrada) {
            entrada["contenidoid"] = limpiarString(entrada[datosModal.campo]);
        }

        const infoCopia = { ...info };
        infoCopia.datos[indiceDatos] = entrada;
        infoCopia.filtrados[indiceFiltrado] = entrada;
        setInfo(infoCopia);
    };

    return (
        <div onClick={(e) => cerrarModal(e.target)}>
            {datosModal.entrada !== null && (
                <Dialog open={datosModal.entrada !== null} maxWidth='sm' fullWidth={true}>
                    <DialogTitle>{datosModal.campo}</DialogTitle>
                    <DialogContent className='pb-3'>
                        <TextField
                            value={datosModal.entrada[datosModal.campo]}
                            onChange={cambiarValor}
                            className=' mb-3 w-100'
                        />

                        <Button variant='primary' block>
                            Cerrar
                        </Button>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    );
}

export default DialogoCampos;
