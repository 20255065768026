import Hidden from "@material-ui/core/Hidden";
import React, { useEffect, useState } from "react";
import { isMobileOnly, isTablet } from "react-device-detect";
import DisplayContenidos from "./DisplayContenidos";
import SubNavigationMenu from "./SubNavigationMenu";

function ContenidosPage(props) {
    const { opcion } = props;
    const { localData } = props;
    const tituloMasInfo = localData.idioma === "es" ? "Quiero saber más" : "I want to know more";

    const [objetos, setObjetos] = useState({});
    const [selectedIndex, setSelectedIndex] = useState("");

    const [gridLimit, setGridLimit] = useState(4);

    const fetchDataFromServer = async () => {
        const res = await fetch(
            `${process.env.REACT_APP_API_URL}/informacionWeb/informacion_contenidos.php?opcion=${opcion}`
        );
        const data = await res.json();
        return data;
    };

    const cambiarGridEvento = () => {
        if (isMobileOnly) {
            setGridLimit(1);

            if (window.innerWidth > 500) {
                setGridLimit(2);
            }
        }
    };

    const comprobarGrid = () => {
        if (isTablet) {
            setGridLimit(2);
        } else if (isMobileOnly) {
            setGridLimit(1);

            if (window.innerWidth > 500) {
                setGridLimit(2);
            }
        }
    };

    useEffect(() => {
        setObjetos({});
        fetchDataFromServer().then((informacionServidor) => {
            if (informacionServidor.status === 200) {
                setSelectedIndex(0);
                setObjetos(JSON.parse(informacionServidor.message));
            } else {
                setObjetos({});
            }
            comprobarGrid();
            window.addEventListener("resize", cambiarGridEvento);

            window.scrollTo(0, 0);
        });
    }, [opcion]);

    const esteParrafo = (index) => {
        setSelectedIndex(index);
    };

    let clavesSubmenu = Object.keys(objetos);
    if (opcion === "contact") {
        clavesSubmenu = [...clavesSubmenu, tituloMasInfo];
    } else if (opcion === "news") {
        const claveContacto = localData.idioma === "es" ? "Contacta con nosotros" : "Contact with us";

        clavesSubmenu = [claveContacto, ...clavesSubmenu];
    } else if (opcion === "group-ah") {
        const claveContacto = localData.idioma === "es" ? "Visión" : "Vision";

        clavesSubmenu = [claveContacto, ...clavesSubmenu];
    }

    return (
        <div className='container-fluid pb-3'>
            <div className='row'>
                <Hidden smDown>
                    <div
                        className={`col-2`}
                        // onMouseOver={() => setSubMenuSize(2)}
                        // onMouseLeave={() => setSubMenuSize(1)}
                    >
                        <SubNavigationMenu
                            localData={localData}
                            claves={clavesSubmenu}
                            selectedIndex={selectedIndex}
                            setSelectedIndex={setSelectedIndex}
                        />
                    </div>
                </Hidden>
                <Hidden smDown>
                    <div className={`col-10  p-0`}>
                        <DisplayContenidos
                            tituloMasInfo={tituloMasInfo}
                            objetos={objetos}
                            esteParrafo={esteParrafo}
                            gridLimit={gridLimit}
                            localData={localData}
                            opcion={opcion}
                        />
                    </div>
                </Hidden>
                <Hidden mdUp>
                    <div className={"col-12 p-0"}>
                        <DisplayContenidos
                            tituloMasInfo={tituloMasInfo}
                            objetos={objetos}
                            esteParrafo={esteParrafo}
                            gridLimit={gridLimit}
                            localData={localData}
                            opcion={opcion}
                        />
                    </div>
                </Hidden>
            </div>
        </div>
    );
}

export default ContenidosPage;
