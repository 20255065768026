import React from "react";
import Button from "react-bootstrap/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getDatosVacios, fetchEnviar } from "../apoyo/Formulario/FuncionesYVariables";

const etiquetasEN = [
    "Do not worry, it happens to all of us",
    "Please enter your username so we can contact you in order to recover your account.",
    "Send",
    "Close",
];
const etiquetasES = [
    "No te preocupes, nos pasa a todos",
    "Por favor, introduce tu nombre de usuario para que contactemos contigo y recuperar el acceso a tu cuenta.",
    "Enviar",
    "Cerrar",
];

function DialogPass({ openModal, onClose, localData, setMdDialog }) {
    const etiquetas = localData.idioma === "es" ? etiquetasES : etiquetasEN;

    const [username, setUsername] = React.useState("");

    const enviarMensaje = () => {
        const datos = getDatosVacios();
        datos["nombre"] = username;
        datos["mensaje"] = "Recuperacion de contraseña para el usuario: " + username;
        datos["email"] = "ah";
        datos["endMail"] = "ahasociados.com";
        datos["endMail"] = "gmail.com";
        datos["terms"] = true;

        fetchEnviar(datos).then((response) => {
            if (response.status === 200) {
                setMdDialog(`documentos/dialogos/recuperar/200_${localData.idioma}.md`);
            } else {
                setMdDialog(`documentos/dialogos/formulario/${response.status}_${localData.idioma}.md`);
            }
        });
    };

    const opcionSeleccionada = (opcion) => {
        if (opcion === true) {
            enviarMensaje();
        }
        setUsername("");
        onClose();
    };

    const handleChangeUserName = (event) => {
        setUsername(event.target.value);
    };

    return (
        <div>
            <Dialog open={openModal} onClose={() => opcionSeleccionada(false)} maxWidth='lg'>
                <DialogTitle className={`${localData.modo === "oscuro" ? "masInfoDialogDark" : "masInfoDialogLight"}`}>
                    {etiquetas[0]}
                </DialogTitle>
                <DialogContent
                    className={`${localData.modo === "oscuro" ? "masInfoDialogDark" : "masInfoDialogLight"}`}
                >
                    <DialogContentText
                        className={`${localData.modo === "oscuro" ? "color-letras" : "masInfoDialogLight"}`}
                    >
                        {etiquetas[1]}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        filled
                        className={`input-olvidado`}
                        margin='dense'
                        inputProps={{ className: `${localData.modo === "oscuro" ? "color-letras" : ""} ` }}
                        value={username}
                        label='Username'
                        onChange={handleChangeUserName}
                        fullWidth={true}
                    />
                </DialogContent>

                <DialogActions
                    className={`${localData.modo === "oscuro" ? "masInfoDialogDark" : "masInfoDialogLight"}`}
                >
                    <Button
                        className='w-50'
                        onClick={() => opcionSeleccionada(true)}
                        variant={`outline-success`}
                        disabled={username.length < 3}
                    >
                        {etiquetas[2]}
                    </Button>

                    <Button
                        className='w-50'
                        onClick={() => opcionSeleccionada(false)}
                        variant={`outline-danger`}
                        autoFocus
                    >
                        {etiquetas[3]}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DialogPass;
