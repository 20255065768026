import React from "react";
import TextField from "@material-ui/core/TextField";

function FiltroTexto({ filtrado, setFiltrado }) {
    const cambiarFiltro = (e) => {
        const copiaDatosFiltrados = { ...filtrado };
        copiaDatosFiltrados.texto = e.target.value.toLowerCase();

        setFiltrado(copiaDatosFiltrados);
    };
    return (
        <form noValidate autoComplete='off'>
            <TextField
                variant='filled'
                color='secondary'
                fullWidth
                onChange={cambiarFiltro}
                value={filtrado.texto}
                label='Clave para filtrado'
            />
        </form>
    );
}

export default FiltroTexto;
