import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
function AutoCompleteFiltro({ filtrado, setFiltrado, opciones, campo }) {
    const cambiarCampo = (newValue) => {
        if (newValue === null) {
            newValue = "";
        }
        const filtradoCopia = { ...filtrado };

        filtradoCopia.filtros.valores[campo] = newValue;
        setFiltrado(filtradoCopia);
    };

    return (
        <Autocomplete
            className='w-100'
            onChange={(event, newValue) => cambiarCampo(newValue)}
            options={opciones.map((option) => option)}
            style={{ width: 300 }}
            value={filtrado.filtros.valores[campo]}
            renderInput={(params) => <TextField variant='filled' color='secondary' {...params} label={campo} />}
        />
    );
}

export default AutoCompleteFiltro;
