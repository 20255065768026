import { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import DisplayMarkdown from "../markdown/DisplayMarkdown";
import { isMobile, isMobileOnly } from "react-device-detect";

function TextoContenidos({ localData, titulo, markdown }) {
    const [imagenFondo, setImagenFondo] = useState("documentos/portada/128-VPO-SOTO-31.jpg");
    const fetchDatosPortada = async () => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/informacionWeb/get_datos_portada.php`);
        const data = await res.json();
        return JSON.parse(data.message);
    };
    useEffect(() => {
        fetchDatosPortada().then((informacionServidor) => {
            console.log(informacionServidor);
        });
    }, []);
    return (
        <>
            <div id={titulo} className='div-relleno'></div>

            <Typography
                variant='h4'
                style={{
                    height: "auto",
                }}
                className={`${localData.modo === "oscuro" ? "color-letras" : "color-letras-dark"} `}
                // Titulo de la lista
            >
                {titulo}
            </Typography>
            <div className='texto-wrap'>
                <img className='texto-bg' src={`${process.env.REACT_APP_SRC_URL}${imagenFondo}`} alt='' />
                <div
                    className={`${
                        localData.modo === "oscuro"
                            ? "color-letras texto-content-dark"
                            : "color-letras-dark texto-content-light"
                    }
                       pr-3 pl-3
                     texto-content h-100 ${!isMobileOnly ? "texto-20" : "texto-18"}`}
                >
                    <DisplayMarkdown markdownName={markdown} />
                </div>
            </div>
        </>
    );
}

export default TextoContenidos;
