// Variables

export const extensiones = [
    "gmail.com",
    "outlook.com",
    "yahoo.com",
    "hotmail.com",
    "me.com",
    "aol.com",
    "mac.com",
    "live.com",
    "googlemail.com",
    "msn.com",
    "facebook.com",
    "verizon.net",
    "icloud.com",
    "rediffmail.com",
    "zohomail.in",
    "zoho.com",
    "mail.com",
    "protonmail.com",
    "yandex.com",
    "yahoo.es",
    "hotmail.es",
    "gmail.es",
    "me.es",
    "aol.es",
    "mac.es",
    "live.es",
    "googlemail.es",
    "msn.es",
    "facebook.es",
    "verizon.net",
    "outlook.es",
    "icloud.es",
    "rediffmail.es",
    "zohomail.in",
    "zoho.es",
    "mail.es",
    "protonmail.es",
    "yandex.es",
];

export const etiquetasES = [
    "Me preguntaba...",
    "Mi nombre es...",
    "Mi correo es...",
    "Mi extension es...",
    "Aceptar términos y condiciones",
    "Enviar",
];

export const etiquetasEN = [
    "I wanted to ask...",
    "My name is...",
    "My email address is...",
    "My email extension is...",
    "Accept terms and conditions",
    "Send",
];

export const getDatosVacios = () => {
    return {
        nombre: "",
        email: "",
        endMail: extensiones[0],
        mensaje: "",
        terms: false,
    };
};

// Validar entrada
export const isValidEntrada = (datosMen) => {
    let isValid = datosMen.terms;
    isValid = isValid && datosMen.nombre !== "";
    isValid = isValid && datosMen.email !== "";
    isValid = isValid && datosMen.endMail.split(".").length === 2;
    isValid = isValid && datosMen.mensaje !== "";
    return isValid;
};

export const fetchEnviar = async (datos) => {
    const dataPOST = new FormData();
    dataPOST.append("datos", JSON.stringify(datos));

    const url = `${process.env.REACT_APP_API_URL}/emails/enviar_email.php`;

    const res = await fetch(url, {
        method: "POST",
        body: dataPOST,
    });
    const data = await res.json();
    return data;
};
