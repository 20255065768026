import React from "react";
import Button from "react-bootstrap/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { fetchCrearNuevaEntrada, fetchContent } from "../../funciones/PeticionesServidor";
import { filtrarDatos } from "../../funciones/FilasFunciones";
function BotonNuevaEntrada({ info, setInfo, opciones, setOpciones, filtrado, tiposEntrada = [] }) {
    const [open, setOpen] = React.useState(false);
    const [ident, setIdent] = React.useState("");

    const handleClose = () => {
        if (ident === "") {
            setOpen(false);
        } else {
            nuevaEntrada();
        }
    };

    const nuevaEntrada = () => {
        const infoCopia = { ...info };
        const datosCopia = infoCopia.datos;
        let campoId = info.campo;

        let orden = (parseInt(datosCopia[datosCopia.length - 1]["orden"]) + 1).toString();

        if (infoCopia.tipo !== "agrupaciones" && info.tipo !== "contenidos" && infoCopia.campo !== "") {
            // Aqui hay que poner un modal que se abra pidiendo el id

            if (ident !== "") {
                orden = 0;

                datosCopia.forEach((entrada) => {
                    if (entrada[campoId] === ident) {
                        const ordenNuevo = parseInt(entrada["orden"]) + 1;
                        if (ordenNuevo > orden) {
                            orden = ordenNuevo;
                        }
                    }
                });
                setOpen(false);
            } else {
                setOpen(true);
                return false;
            }
        }

        if (info.tipo === "contenidos") {
            campoId = "";
        }
        fetchCrearNuevaEntrada(info.tipo, orden, ident, campoId).then(() => {
            fetchContent(infoCopia.tipo).then((response) => {
                infoCopia.datos = JSON.parse(response.message);
                infoCopia.filtrados = JSON.parse(response.message);
                setInfo(infoCopia);

                filtrarDatos(infoCopia, setInfo, opciones, setOpciones, filtrado);
                const opcionesCopia = { ...opciones };
                opcionesCopia.pagina = Math.floor(info.filtrados.length / opcionesCopia.filasPagina);

                setOpciones(opcionesCopia);
                setIdent("");
            });
        });
    };
    return (
        <>
            <Button onClick={nuevaEntrada} className='w-100' size='lg'>
                Nueva entrada
            </Button>

            {info.campo !== "" && info.tipo !== "contenidos" && (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby='form-dialog-title'
                    maxWidth='xs'
                    fullWidth={true}
                >
                    <DialogTitle id='form-dialog-title'>Nueva entrada</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Elige el tipo de entrada</DialogContentText>
                        <Autocomplete
                            className='w-100'
                            onChange={(event, newValue) => setIdent(newValue)}
                            options={tiposEntrada.map((option) => option)}
                            style={{ width: 300 }}
                            value={ident}
                            renderInput={(params) => (
                                <TextField variant='filled' color='secondary' {...params} label={info.campo} />
                            )}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color='primary'>
                            Seleccionar
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}

export default BotonNuevaEntrada;
