import React from "react";
import Button from "react-bootstrap/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

function DialogAceptar({ openModal, confirmacion, onClose, titulo, texto, localData }) {
    const opcionSeleccionada = (opcion) => {
        confirmacion(opcion);
        onClose();
    };

    return (
        <div>
            <Dialog open={openModal} onClose={() => opcionSeleccionada(false)} maxWidth='lg'>
                <DialogTitle className={`${localData.modo === "oscuro" ? "masInfoDialogDark" : "masInfoDialogLight"}`}>
                    {titulo}
                </DialogTitle>
                <DialogContent
                    className={`${localData.modo === "oscuro" ? "masInfoDialogDark" : "masInfoDialogLight"}`}
                >
                    {texto}
                </DialogContent>

                <DialogActions
                    className={`${localData.modo === "oscuro" ? "masInfoDialogDark" : "masInfoDialogLight"}`}
                >
                    <Button className='w-50' onClick={() => opcionSeleccionada(true)} variant={`outline-success`}>
                        {localData.idioma === "es" ? "Aceptar" : "Accept"}
                    </Button>

                    <Button
                        className='w-50'
                        onClick={() => opcionSeleccionada(false)}
                        variant={`outline-danger`}
                        autoFocus
                    >
                        {localData.idioma === "es" ? "Cerrar" : "Close"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DialogAceptar;
