import React from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import { getIndexEntrada, limpiarString } from "../../funciones/FilasFunciones";
import Tooltip from "@material-ui/core/Tooltip";
function TextInput({ info, setInfo, entrada, campo, opciones, setOpciones }) {
    const cambiarFila = (e) => {
        const indiceFiltrado = getIndexEntrada(entrada, info.filtrados);
        const indiceDatos = getIndexEntrada(entrada, info.datos);

        entrada[campo] = e.target.value;
        if (campo === "titulo_en" && "id_str" in entrada) {
            entrada["id_str"] = limpiarString(entrada[campo]);
        } else if (campo === "titulo_en" && "contenidoid" in entrada) {
            entrada["contenidoid"] = limpiarString(entrada[campo]);
        }

        const infoCopia = { ...info };
        infoCopia.datos[indiceDatos] = entrada;
        infoCopia.filtrados[indiceFiltrado] = entrada;

        setInfo(infoCopia);
    };

    const handleOpenModal = () => {
        const opcionesCopia = { ...opciones };
        opcionesCopia.datosModal = { entrada, campo, info, setInfo };
        setOpciones(opcionesCopia);
    };

    return (
        <TextField
            value={entrada[campo]}
            fullWidth
            onChange={cambiarFila}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end' onClick={handleOpenModal}>
                        <Tooltip title='Ampliar texto'>
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default TextInput;
