export const getDefaultAuthData = () => {
    return { usuario: "", rol: "" };
};

export const isValidAdmin = () => {
    let authData = getDefaultAuthData();
    if (window.sessionStorage.getItem("auth") !== null) {
        authData = JSON.parse(window.sessionStorage.getItem("auth"));
    }
    return authData.rol === "admin";
};

export const isValidTrabajador = () => {
    let authData = getDefaultAuthData();
    if (window.sessionStorage.getItem("auth") !== null) {
        authData = JSON.parse(window.sessionStorage.getItem("auth"));
    }
    return authData.rol === "trabajador" || authData.rol === "admin";
};

export const isValidUser = () => {
    return window.sessionStorage.getItem("auth") !== null;
};

export const fetchLogin = async (user, password) => {
    const dataPOST = new FormData();
    dataPOST.append("usuario", user);
    dataPOST.append("password", password);
    const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/login.php`, {
        method: "POST",
        body: dataPOST,
    });
    const data = await res.json();
    return data;
};

export const fetchLogout = async (user, password) => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/logout.php`);
    const data = await res.json();
    return data;
};
