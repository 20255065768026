import React from "react";
import Toolbar from "@material-ui/core/Toolbar";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AppBar from "@material-ui/core/AppBar";
import AgrupacionesTab from "./tablas/AgrupacionesTab";
import SubAgrupacionesTab from "./tablas/SubagrupacionesTab";
import AsociacioensTab from "./tablas/AsociacionesTab";
import ContenidosTab from "./tablas/ContenidosTab";
import DetallesTab from "./tablas/DetallesTab";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import DocumentosPage from "./documentos/DocumentosPage";
import UsuarioPage from "./usuarios/UsuariosPage";
import { isValidAdmin, isValidTrabajador } from "../../functions/AuthFunctions";
let opciones = ["Base de datos", "Documentos", "Usuarios"];

function TabPanel({ value }) {
    return (
        <div>
            {value === 0 && <AgrupacionesTab />}
            {value === 1 && <SubAgrupacionesTab />}
            {value === 2 && <AsociacioensTab />}
            {value === 3 && <ContenidosTab />}
            {value === 4 && <DetallesTab />}
        </div>
    );
}

function AdminPage({ localData }) {
    if (!isValidAdmin()) {
        if (isValidTrabajador()) {
            opciones = ["Base de datos", "Documentos"];
        } else {
            opciones = [];
        }
    }

    const [tab, setTab] = React.useState(0);
    const [opcionAdmin, setOpcionAdmin] = React.useState(opciones[0]);

    const changeTab = (event, newValue) => {
        setTab(newValue);
    };

    const changeOpcionAdmin = (event) => {
        setOpcionAdmin(event.target.value);
    };

    return (
        <div className={`p-3 ${localData.modo === "oscuro" ? "bg-dark" : ""}`} style={{ height: "100vh" }}>
            <Toolbar />
            <Select
                id='demo-simple-select'
                value={opcionAdmin}
                variant='filled'
                color='secondary'
                onChange={changeOpcionAdmin}
                className='mb-3 select-admin'
            >
                {opciones.map((opcion, index) => {
                    return <MenuItem value={opcion}>{opcion}</MenuItem>;
                })}
            </Select>
            {opciones[0] === opcionAdmin && (
                <>
                    <AppBar position='static'>
                        <Tabs value={tab} onChange={changeTab} variant='fullWidth' className='bg-dark'>
                            <Tab label='Agrupaciones' />
                            <Tab label='Subagrupaciones' />
                            <Tab label='Asociaciones' />
                            <Tab label='Contenidos' />
                            <Tab label='Detalles' />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={tab}>Item One</TabPanel>{" "}
                </>
            )}

            {opciones[1] === opcionAdmin && <DocumentosPage />}
            {opciones[2] === opcionAdmin && <UsuarioPage />}
        </div>
    );
}

export default AdminPage;
