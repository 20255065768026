import { useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import Button from "react-bootstrap/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";

import DialogoConfirmar from "../DialogoConfirmar";
import DialogInformacion from "../DialogInformacion";
import DisplayMarkdown from "../../markdown/DisplayMarkdown";
import {
    extensiones,
    isValidEntrada,
    getDatosVacios,
    fetchEnviar,
    etiquetasES,
    etiquetasEN,
} from "./FuncionesYVariables";
import { withStyles } from "@material-ui/core/styles";

const CustomCheck = withStyles({
    root: {
        color: "lightgray",
        "&$checked": {
            color: "royalblue",
        },
    },
    checked: {},
})((props) => <Checkbox color='default' {...props} />);

function FormularioContacto({ localData }) {
    const etiquetas = localData.idioma === "es" ? etiquetasES : etiquetasEN;

    const [datosMen, setDatosMen] = useState(getDatosVacios());
    const [mdDialog, setMdDialog] = useState("");

    const [terminosAbierto, setTerminosAbierto] = useState(false);

    // Cambiar valores del formulario
    const cambiarCampo = (campo, valor) => {
        if (valor === null) {
            valor = "";
        }
        const datosMenCopia = { ...datosMen };
        datosMenCopia[campo] = valor;

        setDatosMen(datosMenCopia);
    };
    const cambiarCampoEvent = (campo, event) => {
        cambiarCampo(campo, event.target.value);
    };

    // Cambiar terminos del formulario
    const cambiarTerminos = () => {
        const checkedNuevo = !datosMen.terms;
        if (checkedNuevo === true) {
            setTerminosAbierto(true);
        } else {
            cambiarCampo("terms", checkedNuevo);
        }
    };
    const confirmFunc = (opcion) => {
        cambiarCampo("terms", opcion);
    };

    // Enviar el mensaje

    const enviarMensaje = () => {
        fetchEnviar(datosMen).then((response) => {
            setMdDialog(`documentos/dialogos/formulario/${response.status}_${localData.idioma}.md`);
        });
    };

    const cerrarDialogoInfo = () => {
        setMdDialog("");
    };

    return (
        <div>
            <div className={`${localData.modo === "claro" ? "bg-formulario-light" : "bg-formulario-dark"} p-4`}>
                <div>
                    <TextField
                        className={`w-100 ${localData.modo === "oscuro" ? "formulario-input-dark" : ""}`}
                        value={datosMen.mensaje}
                        onChange={(event) => cambiarCampoEvent("mensaje", event)}
                        multiline
                        rows={8}
                        variant='outlined'
                        label={etiquetas[0]}
                    />
                </div>

                <div className='mt-3'>
                    <TextField
                        className={`w-100 ${localData.modo === "oscuro" ? "formulario-input-dark" : ""}`}
                        label={etiquetas[1]}
                        value={datosMen.nombre}
                        onChange={(event) => cambiarCampoEvent("nombre", event)}
                    />
                </div>

                <div className='mt-3'>
                    <TextField
                        className={`w-100 ${localData.modo === "oscuro" ? "formulario-input-dark" : ""}`}
                        label={etiquetas[2]}
                        value={datosMen.email}
                        onChange={(event) => cambiarCampoEvent("email", event)}
                    />
                </div>

                <div className='mt-3'>
                    <Autocomplete
                        freeSolo
                        className={`w-100 ${localData.modo === "oscuro" ? "formulario-input-dark" : ""}`}
                        options={extensiones.map((option) => option)}
                        style={{ width: 300 }}
                        value={datosMen.endMail}
                        renderInput={(params) => (
                            <TextField
                                color='primary'
                                onChange={(event) => cambiarCampoEvent("endMail", event)}
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment
                                            position='start'
                                            className={`${localData.modo === "oscuro" ? "color-letras" : ""}`}
                                        >
                                            <AlternateEmailIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                label={etiquetas[3]}
                            />
                        )}
                    />
                </div>
                <div className={`mt-3 w-100 ${localData.modo === "oscuro" ? "color-letras" : ""}`}>
                    <FormControlLabel
                        onChange={cambiarTerminos}
                        checked={datosMen.terms}
                        control={<CustomCheck />}
                        label={etiquetas[4]}
                    />
                </div>
                <div className=' text-center mt-3 '>
                    <Tooltip
                        title={!isValidEntrada(datosMen) ? "Faltan datos por rellenar." : "Enviar pregunta."}
                        arrow
                    >
                        <Button
                            variant={`${localData.modo === "oscuro" ? "outline-light" : "outline-dark"}`}
                            className='w-100'
                            disabled={!isValidEntrada(datosMen)}
                            onClick={enviarMensaje}
                        >
                            {etiquetas[5]}
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <DialogoConfirmar
                openModal={terminosAbierto}
                onClose={() => setTerminosAbierto(false)}
                confirmacion={confirmFunc}
                titulo='Términos y condiciones'
                texto={<DisplayMarkdown markdownName='documentos/legales/terminos_condiciones.md' />}
                localData={localData}
            />
            <DialogInformacion onClose={() => cerrarDialogoInfo()} mdDialog={mdDialog} localData={localData} />
        </div>
    );
}

export default FormularioContacto;
