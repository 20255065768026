import { useState } from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import { getIndexEntrada } from "../comunes/funciones/FilasFunciones";

function PasswordInput({ info, setInfo, entrada, campo }) {
    const [show, setShow] = useState(false);

    const cambiarFila = (e) => {
        const indiceFiltrado = getIndexEntrada(entrada, info.filtrados);
        const indiceDatos = getIndexEntrada(entrada, info.datos);

        entrada[campo] = e.target.value;
        const infoCopia = { ...info };
        infoCopia.datos[indiceDatos] = entrada;
        infoCopia.filtrados[indiceFiltrado] = entrada;

        setInfo(infoCopia);
    };
    const cambiarVisible = () => {
        setShow(!show);
    };

    return (
        <TextField
            value={entrada[campo]}
            fullWidth
            type={show ? "text" : "password"}
            onChange={cambiarFila}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end' onClick={cambiarVisible}>
                        <Tooltip title='Ver contraseña'>
                            <IconButton>{show ? <Visibility /> : <VisibilityOff />}</IconButton>
                        </Tooltip>
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default PasswordInput;
