import React from "react";

import { Link, useLocation } from "react-router-dom";
import DialogInformacion from "../apoyo/DialogInformacion";
function Footer({ localData }) {
    const [mdDialog, setMdDialog] = React.useState("");
    const location = useLocation();
    const cerrarDialogoInfo = () => {
        setMdDialog("");
    };
    const abrirDialogo = () => {
        if (localData.idioma === "es") {
            setMdDialog("documentos/legales/terminos_condiciones.md");
        } else {
            setMdDialog("documentos/legales/terminos_condiciones.md");
        }
    };
    const isFixed = () => {
        const pathsFixed = ["/", "/user", "/admin", "/login", "/editor"];

        return pathsFixed.indexOf(location.pathname) !== -1;
    };

    return (
        <footer className={`text-center footer ${isFixed() ? "footer-fixed" : ""}`}>
            <Link variant='link' onClick={abrirDialogo} className='color-letras' to='#'>
                {localData.idioma === "es" ? "Terminos y condiciones" : "Terms of use"}
            </Link>
            <DialogInformacion onClose={() => cerrarDialogoInfo()} mdDialog={mdDialog} localData={localData} />
        </footer>
    );
}

export default Footer;
