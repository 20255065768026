import React from "react";

import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Button from "react-bootstrap/Button";

import { isValidTrabajador, isValidAdmin } from "../../functions/AuthFunctions";
function Herramientas({ localData }) {
    const fectchLimpiarServidor = async () => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/ahAdminFiles/administrador/limpiar_documentos.php`);
        const data = await res.json();
        return data;
    };

    const limpiarServidor = () => {
        if (isValidAdmin()) {
            fectchLimpiarServidor().then((response) => {
                const datos = JSON.parse(response.message);

                const textoDialogo = `Se han borrado ${datos["contenidos"]} archivos de contenidos y ${datos["detalles"]} archivos de detalles`;
                alert(textoDialogo);
            });
        }
    };

    return (
        <div
            className={` container-fluid menuLogin ${
                localData.modo === "oscuro" ? "menuLoginDark" : "menuLoginLight"
            } text-center h-100`}
        >
            <List className=''>
                {isValidTrabajador() && (
                    <>
                        <Link to='/admin' className='linksToolbar color-letras-dark'>
                            <ListItem>
                                <Button className='w-100' variant='outline-dark' size='lg'>
                                    Admin
                                </Button>
                            </ListItem>
                        </Link>
                        <Link to='/editor' className='linksToolbar color-letras-dark'>
                            <ListItem>
                                <Button className='w-100' variant='outline-dark' size='lg'>
                                    Editor
                                </Button>
                            </ListItem>
                        </Link>
                    </>
                )}
                {isValidAdmin() && (
                    <ListItem>
                        <Button className='w-100' variant='outline-dark' size='lg' onClick={limpiarServidor}>
                            Limpiar servidor
                        </Button>
                    </ListItem>
                )}
            </List>
        </div>
    );
}

export default Herramientas;
