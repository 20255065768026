/* eslint-disable react/jsx-no-target-blank */
import { makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";

import {useHistory, useLocation,Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import { useState, useEffect } from "react";

import logoAhDark from "../.././assets/generals/trainn_dark.png";
import logoAhLight from "../.././assets/generals/trainn_light.png";

import BarraTareas from "./HeaderComponents/BarraTareas";
import ContenidoMenu from "./HeaderComponents/ContenidoMenu";

import { getDefaultAuthData } from "../../functions/AuthFunctions";
import { changeLocalData, SI_POLI, fetchCambiarIdioma, fetchCambiarPais } from "../../functions/AppFunctions";
const drawerWidth = "16.67%";
const minDrawerWidth = "180px";

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: "rgba(0,0,0,0.4)",
        "&:hover": {
            backgroundColor: "black",
        },
    },

    menuButton: {
        marginRight: theme.spacing(2),
        flexGrow: 1,
    },
    drawer: {
        minWidth: minDrawerWidth,
        width: drawerWidth,
        flexShrink: 0,
        backgroundColor: "none",
    },

    drawerTemporaryPaperDark: {
        minWidth: minDrawerWidth,
        width: drawerWidth,
        backgroundColor: "rgba(0,0,0,1)",
    },
    drawerTemporaryPaperLight: {
        minWidth: minDrawerWidth,
        width: drawerWidth,
        backgroundColor: "rgba(255,255,255,1)",
    },
}));

export default function Header({ openMenu, setOpenMenu, localData, setLocalData, checkPolitica,dataUserLogged, setDataUserLogged }) {
    const [menuOptions, setMenuOptions] = useState([]);

    const fetchMenuOptions = async () => {
        const res = await fetch(`${process.env.REACT_APP_API_URL}/informacionWeb/opciones_menu.php`);
        const data = await res.json();
        return data;
    };
    useEffect(() => {
        fetchMenuOptions().then((informacionServidor) => {
            setMenuOptions(informacionServidor);
        });
        if (window.sessionStorage.getItem("auth") !== null) {
            const authData = JSON.parse(window.sessionStorage.getItem("auth"));
            setDataUserLogged(authData);
        }
    }, []);

    const classes = useStyles();

    // Cambiar cositas

    const cambiarModo = () => {
        if (localData.politica === SI_POLI) {
            const modoNuevo = localData.modo === "oscuro" ? "claro" : "oscuro";

            changeLocalData(localData, setLocalData, "modo", modoNuevo);
        }
    };

    const history = useHistory();
    const location = useLocation();

    const windowReload=()=>{
        const newLocation = `${location.pathname}${location.search}`;
        history.push(newLocation);
    }

    const cambiarIdioma = () => {
        const idioma = localData.idioma === "es" ? "en" : "es";

        fetchCambiarIdioma(idioma).then((response) => {
            if (response.status === 200 || response.status === 202) {
                changeLocalData(localData, setLocalData, "idioma", idioma);
                windowReload();
            } else if (response.status === 404) {
                alert("Ha habido un problema con el cambio de idioma.");
            }
        });
    };

    const cambiarPais = (pais) => {
        if (localData.politica === SI_POLI && pais !== localData.pais) {
            fetchCambiarPais(pais).then((response) => {
                if (response.status === 200 || response.status === 202) {
                    changeLocalData(localData, setLocalData, "pais", pais);
                    windowReload();
                } else if (response.status === 404) {
                    alert("Ha habido un problema con el cambio de idioma.");
                }
            });
        }
    };

    const changeMenu = () => {
        setOpenMenu(!openMenu);
    };

    // Esto tengo que decirselo --> Opciones submenu deben estar controladas
    const closeMenu = (event) => {
        if (event.target.textContent !== "Actividad" && event.target.textContent !== "Activity") {
            setOpenMenu(false);
        }
    };
    return (
        <>
            <CssBaseline />
            <AppBar position='fixed' className={classes.appBar}>
                <Toolbar style={{ minHeight: "0px" }} className={`${openMenu ? "d-none" : ""}`}>
                    <IconButton onClick={changeMenu} id='logobtn' className={`botonColor p-0 `}>
                        <MenuIcon />
                    </IconButton>

                    <BarraTareas
                        localData={localData}
                        cambiarModo={cambiarModo}
                        cambiarIdioma={cambiarIdioma}
                        cambiarPais={cambiarPais}
                    />
                </Toolbar>
            </AppBar>
            <nav onClick={checkPolitica}>
                <Drawer
                    onClick={closeMenu}
                    className={classes.drawer}
                    variant='temporary'
                    anchor='left'
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    open={openMenu}
                    classes={{
                        paper:
                            localData.modo === "oscuro"
                                ? classes.drawerTemporaryPaperDark
                                : classes.drawerTemporaryPaperLight,
                    }}
                >
                    <Link to={"/"} className='pl-3'>
                        <img
                            className='logoPrincipal '
                            src={localData.modo === "oscuro" ? logoAhDark : logoAhLight}
                            alt='Trainn logo'
                        />
                    </Link>

                    <ContenidoMenu
                        dataUserLogged={dataUserLogged}
                        menuOptions={menuOptions}
                        localData={localData}
                        setOpenMenu={setOpenMenu}
                    />
                </Drawer>
            </nav>
        </>
    );
}
