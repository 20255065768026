import React from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import RenderLinks from "./RenderLinks";

export default function RenderCode(props) {
    // Revisar más adelante cuando quiera varios tipos de botones y demas
    if (!("value" in props)) {
        return <></>;
    }

    const textos = props.value.trim().split(";;;");

    const colClass = `col-sm-${12 / textos.length} text-justify`;

    const isLink = (texto) => {
        return texto.includes("[") && texto.includes("]") && texto.includes("(") && texto.includes(")");
    };

    return (
        <>
            <div>
                <div className='row'>
                    {textos.map((contenido, index) => {
                        if (isLink(contenido)) {
                            const titular = contenido.split("[")[0];

                            const value = contenido.split("[")[1].split("]")[0].trim();
                            const href = contenido.split("(")[1].split(")")[0].trim();

                            const objetoMandar = [{ props: { value, href } }];
                            return (
                                <div className={colClass} key={index}>
                                    {titular.trim() !== "" && (
                                        <ReactMarkdown
                                            className='text-left letra-familia'
                                            style={{ maxWidth: "100%" }}
                                            children={titular}
                                            remarkPlugins={[gfm, { singleTilde: false }]}
                                            renderers={{ link: RenderLinks }}
                                        ></ReactMarkdown>
                                    )}

                                    <RenderLinks href={href} children={objetoMandar}></RenderLinks>
                                </div>
                            );
                        } else {
                            const parrafos = contenido.split("\n");

                            return (
                                <div className={colClass}>
                                    {parrafos.map((parrafo, index) => {
                                        if (parrafo === "") {
                                            return <br key={index}></br>;
                                        }
                                        return (
                                            <ReactMarkdown
                                                key={index}
                                                className='text-left letra-familia'
                                                style={{ maxWidth: "100%" }}
                                                children={parrafo}
                                                remarkPlugins={[gfm, { singleTilde: false }]}
                                                renderers={{ link: RenderLinks }}
                                            ></ReactMarkdown>
                                        );
                                    })}
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        </>
    );
}
