import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

import ReactCountryFlag from "react-country-flag";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";

import DialogPais from "./DialogPais";

function BarraTareas({ localData, cambiarModo, cambiarIdioma, cambiarPais }) {
  const [openCambiar, setOpenCambiar] = React.useState(false);

  return (
    <div className="text-right w-100">
      <Tooltip
        title={
          localData.idioma === "es"
            ? "Cambiar tema oscuro/claro"
            : "Toggle dark/light theme"
        }
        arrow
      >
        <IconButton
          className="botonColor linksToolbarDark ml-3"
          size="small"
          onClick={cambiarModo}
        >
          {localData.modo === "oscuro" ? (
            <Brightness7Icon />
          ) : (
            <Brightness4Icon />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip
        title={localData.idioma === "es" ? "Cambiar idioma" : "Change language"}
        arrow
      >
        <Link
          className="linksToolbarDark ml-3"
          onClick={() => {
            cambiarIdioma();
          }}
          to={window.location.pathname}
        >
          {localData.idioma === "es" ? "ES" : "EN"}
        </Link>
      </Tooltip>
      {/* <Tooltip title={localData.idioma === "es" ? "Cambiar país mostrado" : "Change country shown"} arrow>
                <IconButton onClick={() => setOpenCambiar(true)} className=' botonColor ml-1' size='small'>
                    <ReactCountryFlag countryCode={localData.pais} svg />
                </IconButton>
            </Tooltip> */}
      {/*
            <IconButton
                className=' botonColor ml-1'
                target='_blank'
                size='small'
                href=''
            >
                <LinkedInIcon />
            </IconButton>
            <IconButton
                className='botonColor'
                target='_blank'
                href=''
                size='small'
            >
                <FacebookIcon />
            </IconButton>
            <IconButton
                size='small'
                className='botonColor'
                target='_blank'
                href=''
            >
                <InstagramIcon />
            </IconButton>
            <IconButton size='small' className='botonColor' target='_blank' href=''>
                <TwitterIcon />
            </IconButton>
            */}
      <DialogPais
        open={openCambiar}
        onClose={() => setOpenCambiar(false)}
        cambiarPais={cambiarPais}
        localData={localData}
      />
    </div>
  );
}

export default BarraTareas;
