import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import { useEffect } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import logoTrainn from "../.././assets/generals/trainn_light.png";
import { Redirect } from "react-router-dom";
import DialogInformacion from "../apoyo/DialogInformacion";
import Button from "react-bootstrap/Button";

import { fetchLogin } from "../../functions/AuthFunctions";
import DialogPass from "./DialogPass";

function Login({ localData }) {
  const [user, setUser] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [mdDialog, setMdDialog] = React.useState("");
  const [openOlv, setOpenOlv] = React.useState(false);

  useEffect(() => {
    // document.getElementById("logobtn").click();
  }, []);

  if (window.sessionStorage.getItem("auth") !== null) {
    return <Redirect to="/" />;
  }

  const handleChangeUser = (event) => {
    setUser(event.target.value);
  };
  const handleChangePass = (event) => {
    setPassword(event.target.value);
  };

  const handleClickLogin = () => {
    fetchLogin(user, password).then((response) => {
      if (response.status === 200) {
        window.sessionStorage.setItem("auth", response.message);
        window.location.reload();
      } else if (response.status === 400) {
        setMdDialog(`documentos/dialogos/login/400_${localData.idioma}.md`);
        setPassword("");
      } else if (response.status === 403) {
        setMdDialog(`documentos/dialogos/login/403_${localData.idioma}.md`);
        setPassword("");
      }
    });
  };

  return (
    <>
      <Toolbar />
      <Container
        maxWidth="sm"
        className={`menuLogin ${
          localData.modo === "oscuro" ? "menuLoginDark" : "menuLoginLight"
        }`}
      >
        <form autoComplete="off" className="text-center ">
          <div>
            {" "}
            <img className="logoLogin " src={logoTrainn} alt="Trainn Logo" />
          </div>
          <div className="mt-3">
            <TextField
              label={localData.idioma === "es" ? "Usuario" : "Username"}
              className="w-50 text-light"
              onChange={(event) => {
                handleChangeUser(event);
              }}
            />
          </div>
          <div className="mt-3">
            <TextField
              type="password"
              label={localData.idioma === "es" ? "Contraseña" : "Password"}
              className="w-50"
              value={password}
              onChange={(event) => {
                handleChangePass(event);
              }}
            />
          </div>
          <div className="mt-3">
            <Button
              className="w-50"
              onClick={handleClickLogin}
              variant="dark"
              disabled={password === "" || user === ""}
            >
              {localData.idioma === "es" ? "Entrar" : "Submit"}
            </Button>
          </div>
        </form>
        <div className="text-center">
          <Button
            className="mt-3 "
            variant="link"
            onClick={() => setOpenOlv(true)}
          >
            {localData.idioma === "es"
              ? "He olvidado mi contraseña"
              : "I have forgotten my password"}
          </Button>
        </div>
      </Container>
      <DialogInformacion
        onClose={() => setMdDialog("")}
        mdDialog={mdDialog}
        localData={localData}
      />
      <DialogPass
        openModal={openOlv}
        onClose={() => setOpenOlv(false)}
        setMdDialog={setMdDialog}
        localData={localData}
      />
    </>
  );
}

export default Login;
