import React from "react";
import Fab from "@material-ui/core/Fab";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import IconButton from "@material-ui/core/IconButton";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import ZoomOutIcon from "@material-ui/icons/ZoomOut";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import logoAhDark from "../../assets/generals/trainn_dark.png";

function DetailsSlider({ imagenes, estudio, localData, titulo }) {
  const [indexImagen, setIndexImagen] = React.useState(0);
  const indexImagenRef = React.useRef(indexImagen);
  const cambiarFotoTecla = (event) => {
    if (event.keyCode === 39) {
      forwardImage();
    } else if (event.keyCode === 37) {
      backImage();
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", cambiarFotoTecla);

    return () => {
      document.removeEventListener("keydown", cambiarFotoTecla);
    };
  }, []);

  const changeIndexImage = (valor) => {
    indexImagenRef.current = valor; // keep updated

    setIndexImagen(valor);
  };

  const backImage = () => {
    if (indexImagenRef.current > 0) {
      changeIndexImage(indexImagenRef.current - 1);
    } else {
      changeIndexImage(imagenes.length - 1);
    }
  };
  const forwardImage = () => {
    if (indexImagenRef.current < imagenes.length - 1) {
      changeIndexImage(indexImagenRef.current + 1);
    } else {
      changeIndexImage(0);
    }
  };

  return (
    <div
      id="imagenes"
      style={{ height: "100vh", width: "100%", overflow: "hidden" }}
    >
      <div style={{ overflow: "hidden" }}>
        <div className={`d-flex  imagen-slide w-100 text-center `}>
          <div
            id="backImagenSlide"
            className="control-slide"
            onClick={backImage}
          >
            <IconButton className="color-letras icono-wrap">
              <ArrowBackIosIcon className="icons-arrows" />
            </IconButton>{" "}
          </div>

          <div className="flex-grow-1 text-center " style={{ width: "94%" }}>
            <TransformWrapper
              wheel={{ disabled: true }}
              doubleClick={{ mode: "reset" }}
              initialPositionX={0}
              initialPositionY={0}
            >
              {({ zoomIn, zoomOut, resetTransform }) => (
                <React.Fragment>
                  <div className="slide-tools">
                    <ButtonGroup
                      variant="text"
                      style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                    >
                      <IconButton
                        className="color-letras"
                        onClick={(event) => zoomIn(event)}
                      >
                        <ZoomInIcon />
                      </IconButton>
                      <IconButton
                        className="color-letras"
                        onClick={(event) => zoomOut(event)}
                      >
                        <ZoomOutIcon />
                      </IconButton>
                      <IconButton
                        className="color-letras"
                        onClick={(event) => resetTransform(event)}
                      >
                        <ZoomOutMapIcon />
                      </IconButton>
                    </ButtonGroup>
                  </div>
                  <div className="watermark-logo-details text-right">
                    <Fab
                      variant="extended"
                      size={"large"}
                      className={`color-letras icono-wrap studio-slide `}
                    >
                      <img
                        src={logoAhDark}
                        alt="Trainn"
                        className={`img-logo`}
                      />
                    </Fab>
                  </div>

                  <div
                    className={`${
                      localData.modo === "oscuro" ? "color-letras" : ""
                    } titulo-obra text-left`}
                  >
                    {titulo}
                  </div>

                  <TransformComponent>
                    <div className="image-container">
                      <div className="image-content">
                        <img
                          className={`${
                            window.screen.width > window.screen.height
                              ? "h-100"
                              : "w-100"
                          } imagen-slider`}
                          src={
                            process.env.REACT_APP_SRC_URL +
                            imagenes[indexImagen].src
                          }
                          alt="test"
                        />
                        <div
                          className={`${
                            localData.modo === "oscuro"
                              ? "shadow-black-overlay"
                              : "shadow-white-overlay"
                          } `}
                        />
                      </div>
                    </div>
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
          </div>

          <div
            className="control-slide control-slide-right"
            onClick={forwardImage}
          >
            <IconButton className="color-letras icono-wrap">
              <ArrowForwardIosIcon className="icons-arrows" />
            </IconButton>{" "}
          </div>
        </div>
      </div>
      <Fab
        variant="extended"
        size={"small"}
        className={`color-letras icono-wrap number-slide`}
      >
        {indexImagen + 1 + "/" + imagenes.length}
      </Fab>
    </div>
  );
}

export default DetailsSlider;
