import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Collapse from "@material-ui/core/Collapse";
function ContenidoMenu({
  dataUserLogged,
  menuOptions,
  localData,
  setOpenMenu,
}) {
  const [servicesOpen, setServicesOpen] = React.useState(false);

  const useStyles = makeStyles((theme) => ({
    drawerContainer: {
      overflow: "auto",
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }));

  const actividad = () => {
    setServicesOpen(!servicesOpen);
  };

  const classes = useStyles();
  return (
    <div className={classes.drawerContainer}>
      <List>
        {dataUserLogged.usuario !== "" && (
          <>
            <Link
              to={"/user"}
              className={`${
                localData.modo === "oscuro"
                  ? "linksToolbarDark"
                  : "linksToolbarLight"
              }`}
            >
              <ListItem className="nombreToolBar" button>
                <ListItemText
                  primary={dataUserLogged.usuario}
                  primaryTypographyProps={{ style: { fontWeight: "bold" } }}
                />
              </ListItem>
            </Link>
          </>
        )}

        {menuOptions.map((opcion, _) => (
          <>
            {
              <Link
                to={"/trainn/" + opcion.id_str}
                className={`${
                  localData.modo === "oscuro"
                    ? "linksToolbarDark"
                    : "linksToolbarLight"
                }`}
                key={opcion.id_str}
              >
                <ListItem button className="pt-0 pb-0">
                  <ListItemText primary={opcion.titulo} />
                </ListItem>
              </Link>
            }
          </>
        ))}
        {/* 
                {dataUserLogged.usuario === "" && (
                    <Link to='/login' className='linksToolbar text-success'>
                        <ListItem button className='pt-0 pb-0'>
                            <ListItemText primary={`${localData.idioma === "es" ? "Iniciar sesión" : "Log in"}`} />
                        </ListItem>
                    </Link>
                )} */}
      </List>
    </div>
  );
}

export default ContenidoMenu;
