import React from "react";
import TablePagination from "@material-ui/core/TablePagination";

import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import FastForwardIcon from "@material-ui/icons/FastForward";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();

    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleSkip5Back = (event) => {
        let paginasCambio = page - 5;

        if (0 > paginasCambio) {
            paginasCambio = 0;
        }
        onChangePage(event, paginasCambio);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleSkip5Forw = (event) => {
        let paginasCambio = page + 5;

        if (Math.max(0, Math.ceil(count / rowsPerPage) - 1) < paginasCambio) {
            paginasCambio = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
        }
        onChangePage(event, paginasCambio);
    };
    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label='first page'>
                <FirstPageIcon />
            </IconButton>
            <IconButton onClick={handleSkip5Back} disabled={page === 0} aria-label='5 pages backwards'>
                <FastRewindIcon />
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
                <KeyboardArrowLeft />
            </IconButton>
            <span>{page}</span>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label='next page'
            >
                {" "}
                <KeyboardArrowRight />
            </IconButton>
            <IconButton
                onClick={handleSkip5Forw}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label='5 pages forward'
            >
                <FastForwardIcon />
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label='last page'
            >
                <LastPageIcon />
            </IconButton>
        </div>
    );
}

function PaginacionTabla({ info, opciones, setOpciones }) {
    const handleChangePage = (event, newPage) => {
        const opcionesCopia = { ...opciones };
        opcionesCopia.pagina = newPage;

        setOpciones(opcionesCopia);
    };
    const handleChangeRowsPerPage = (event) => {
        const opcionesCopia = { ...opciones };
        opcionesCopia.filasPagina = event.target.value;
        opcionesCopia.pagina = 0;
        setOpciones(opcionesCopia);
    };

    return (
        <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component='div'
            count={info.filtrados.length}
            rowsPerPage={opciones.filasPagina}
            page={opciones.pagina}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
        />
    );
}

export default PaginacionTabla;
