import React from "react";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Tooltip from "@material-ui/core/Tooltip";
function ReadOnlyTextInput({ entrada, campo, opciones, setOpciones, info, setInfo }) {
    if (entrada[campo] === null) {
        entrada[campo] = "";
    }
    const handleOpenModal = () => {
        const opcionesCopia = { ...opciones };
        opcionesCopia.datosModal = { entrada, campo, info, setInfo };
        setOpciones(opcionesCopia);
    };
    return (
        <TextField
            variant='filled'
            value={entrada[campo]}
            style={{ minWidth: 150 }}
            InputProps={{
                readOnly: true,
                endAdornment: (
                    <InputAdornment
                        position='end'
                        onClick={() => handleOpenModal(entrada.orden, entrada.id_str, "Campo inalterable")}
                    >
                        <Tooltip title='Ampliar texto'>
                            <IconButton>
                                <SearchIcon />
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                ),
            }}
            disabled
            fullWidth
        />
    );
}

export default ReadOnlyTextInput;
