import React from "react";
import ReactMarkdown from "react-markdown";
import RenderLinks from "./RenderLinks";
import RenderCode from "./RenderCode";
import gfm from "remark-gfm";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";

function DisplayMarkdown({ markdownName }) {
    const [texto, setTexto] = React.useState("");

    const fetchMarkdownInfo = async () => {
        const res = await fetch(
            `${process.env.REACT_APP_API_URL}/informacionWeb/get_markdown.php?mdFile=${markdownName}`
        );
        const data = await res.json();
        return data["message"];
    };

    useEffect(() => {
        fetchMarkdownInfo().then((informacionServidor) => {
            setTexto(informacionServidor);
        });
    }, []);

    return (
        <div
            className={`${
                markdownName.includes("documentos/dialogos/entradas/sin_texto") ? "markdown-no-content" : ""
            }`}
        >
            <ReactMarkdown
                className={`${isMobile ? "text-left" : "text-justify"} letra-familia parrafo-md`}
                children={texto}
                remarkPlugins={[gfm, { singleTilde: false }]}
                renderers={{ link: RenderLinks, code: RenderCode }}
            ></ReactMarkdown>
        </div>
    );
}

export default DisplayMarkdown;
