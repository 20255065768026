import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import BuildIcon from "@material-ui/icons/Build";
import BusinessIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/Person";
import Toolbar from "@material-ui/core/Toolbar";
import DatosUsuario from "./DatosUsuario";
import Herramientas from "./Herramientas";
import { isValidTrabajador } from "../../functions/AuthFunctions";

function UsuarioPage({ localData }) {
    return (
        <div>
            <Toolbar />

            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12'>
                        <Tabs
                            className={`${localData.modo === "oscuro" ? "menuLoginDark" : "menuLoginLight"}`}
                            variant='fullWidth'
                            indicatorColor='primary'
                            textColor='primary'
                            aria-label='icon label tabs example'
                            disabled
                            children={{}}
                            style={{ borderRadius: "4px" }}
                        >
                            <Tab
                                icon={isValidTrabajador() ? <BuildIcon /> : <BusinessIcon />}
                                label={isValidTrabajador() ? "Herramientas" : "Proyectos"}
                            />
                            <Tab icon={<PersonIcon />} label='Usuario' />
                        </Tabs>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-6'>{isValidTrabajador() && <Herramientas localData={localData} />}</div>
                    <div className='col-6'>
                        <DatosUsuario localData={localData} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UsuarioPage;
